import React from 'react'

import { ActiveRecord } from '../../domain/caserecord/ActiveRecord'
import { NavigationBar } from './NavigationBar'
import {
  OnQuestionChanged,
  OnRecordAction,
  OnUnitChanged,
  RecordContainer,
} from './RecordContainer'
import { StatusBar } from './StatusBar'

import './RichRecordContainer.css'

export interface RichRecordContainerProps {
  header: JSX.Element
  privateLabels: JSX.Element | null

  record: ActiveRecord
  showCopyDateButton: boolean
  onQuestionChanged: OnQuestionChanged
  onUnitChanged: OnUnitChanged
  onRecordAction: OnRecordAction
}

interface RichRecordCointainerState {
  activeSection: string
  showWarningsOnEmpty: boolean
}

export class RichRecordContainer extends React.Component<
  RichRecordContainerProps,
  RichRecordCointainerState
> {
  constructor(props: RichRecordContainerProps) {
    super(props)
    this.state = {
      activeSection: '',
      showWarningsOnEmpty: false,
    }
  }

  activeSectionChanged(sectionId: string) {
    this.setState({
      activeSection: sectionId,
    })
  }

  render() {
    const record = this.props.record

    return (
      <div className="component-view">
        <div className="edit-record-header">{this.props.header}</div>
        <div className="row">
          <div className="col-2">
            <NavigationBar
              record={record}
              renderPrivate={this.props.privateLabels !== null}
              activeId={this.state.activeSection}
            />
          </div>
          <div className="col-10">
            {this.props.privateLabels}
            <RecordContainer            
              record={record}
              showWarningsOnEmpty={this.state.showWarningsOnEmpty}
              copyDateButton={this.props.showCopyDateButton}
              onQuestionChanged={this.props.onQuestionChanged}
              onUnitChanged={this.props.onUnitChanged}
              onRecordAction={this.props.onRecordAction}
              
              onActiveSectionChanged={this.activeSectionChanged.bind(this)}
            />
          </div>
        </div>

        <div className="cr-status-bar-spacer">&nbsp;</div>
        <div className="fixed-bottom cr-status-bar">
          <StatusBar
            record={record}
            emptyWarningsVisible={this.state.showWarningsOnEmpty}
            onToggleErrors={this.toggleErrors.bind(this)}
          />
        </div>
      </div>
    )
  }

  toggleErrors() {
    this.setState({ showWarningsOnEmpty: !this.state.showWarningsOnEmpty })
  }
}
