import { serverAddr } from "./ServerAddr"

export function fetchUserWithRoles(authToken: string): Promise<Response> {
  let url = `${serverAddr}/api/v1/users/me?include_roles=true`
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken,
    },
    mode: 'cors', // no-cors
  })
}
