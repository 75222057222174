// Original code here
// https://dev.to/jsbroks/firebase-authentication-with-react-and-guarded-routes-41nm
// with some modification required by the typescript.

import { useState, useEffect, useContext, createContext } from 'react'
import { getAuth, User as FirebaseUser } from '@firebase/auth'
import { initializeApp } from 'firebase/app'

import { User } from './User'
import { fetchUserWithRoles } from '../utils/fetch/FetchUsers'

const firebaseConfig = {
  apiKey: 'AIzaSyDKqWhqZqNPAVu13SLQVCDA5I4yRgOYtiI',
  authDomain: 'medicea-ecrf.firebaseapp.com',
  projectId: 'medicea-ecrf',
  storageBucket: 'medicea-ecrf.appspot.com',
  messagingSenderId: '478523020617',
  appId: '1:478523020617:web:4c359240801ef123437698',
}

export const firebaseApp = initializeApp(firebaseConfig)

export const NotLoggedInError = {
  name: 'NotLoggedIn',
  message: 'Not logged in',
}

export interface AuthContextType {
  firebaseUser: FirebaseUser | null
  user: User | null
  err?: Error | null
  logout: () => void
}

export const AuthContext = createContext<AuthContextType>({
  firebaseUser: null,
  user: null,
  err: NotLoggedInError,
  logout: () => {},
})

export const AuthContextProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null)
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const authChangedCb = (firebaseUser: FirebaseUser | null) => {
      console.log('authChangedCb', firebaseUser != null)
      if (firebaseUser != null) {
        setError(null)

        firebaseUser.getIdToken().then((token: string) => {
          console.log('authChangedCb, got user token')
          fetchUserWithRoles(token)
            .then((response) => response.json())
            .then((response) => {
              console.log('fetchUsersWithRoles #1 success')
              setFirebaseUser(firebaseUser)
              setUser(User.fromObject(response))
              setError(null)
            })
            .catch((error) => {
              // Just finishing with "unlogin" on first error causes problems on Firefox and Safare.
              // Those browser can cancel the ajax requests in case of page reload (and we have a page reload on successful login).
              // Having a 2nd, "delayed" attempt is a hacky fix for the FF & Safari problem.

              // console.log('fetchUsersWithRoles error', error)
              // getAuth().signOut()
              // setFirebaseUser(null)
              // setUser(null)
              // setError(error)

              // delayed tried again - maybe just a redirect?
              setTimeout(() => {
                fetchUserWithRoles(token)
                  .then((response) => response.json())
                  .then((response) => {
                    console.log('fetchUsersWithRoles #2 success')
                    setFirebaseUser(firebaseUser)
                    setUser(User.fromObject(response))
                    setError(null)
                  })
                  .catch((error) => {
                    console.log('fetchUsersWithRoles #2 error', error)
                    getAuth().signOut()
                    setFirebaseUser(null)
                    setUser(null)
                    setError(error)
                  })
              }, 2000)
            })
        })
      } else {
        console.log('user is empty, resetting the credentials')
        setFirebaseUser(null)
        setUser(null)
        setError(NotLoggedInError)
      }
    }

    const unsubscribe = getAuth().onAuthStateChanged(authChangedCb, setError)
    return () => unsubscribe()
  }, [])

  const logout = () => {
    getAuth().signOut()
    // will be done by the event (eventually), but for consistency with "app user",
    // we nullify the firebase right away.
    setFirebaseUser(null)
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{ user, firebaseUser, err: error, logout }}
      {...props}
    />
  )
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}

export function authLoading(context: AuthContextType): Boolean {
  return context.user === null && context.err === null
}
