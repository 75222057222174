import { serverAddr } from './ServerAddr'
import { ActiveRecord } from '../../domain/caserecord/ActiveRecord'
import { FormDescription } from '../../domain/caserecord/FormDescription'

export function fetchRecords(
  authToken: string,
  projectId: string,
  formId: string,
  myRecords: boolean = true
): Promise<Response> {
  let url = `${serverAddr}/api/v1/projects/${projectId}/forms/${formId}/records`
  if (myRecords) {
    url = url + '?researcher_id=me'
  }
  // console.log(url)
  return fetch(url, {
    mode: 'cors', // no-cors
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  })
}

export function createRecord(
  authToken: string,
  projectId: string,
  formId: string
): Promise<Response> {
  let url = `${serverAddr}/api/v1/projects/${projectId}/forms/${formId}/records`
  // console.log(url)
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
    },
    body: '{}',
  })
}

export interface RecordListItem {
  record_id: string
  form: FormDescription
  version: number

  created: Date
  updated: Date

  researcher_id: string
  researcher_name: string
}

export function recordListItemFromDict(obj: any): RecordListItem {
  return {
    record_id: obj.record_id,
    form: obj.form,
    version: obj.version,
    created: new Date(obj.created),
    updated: new Date(obj.updated),
    researcher_id: obj.researcher_id,
    researcher_name: obj.researcher_name,
  }
}

export function fetchRecord(
  authToken: string,
  projectId: string,
  formId: string,
  recordId: string
): Promise<Response> {
  let url = `${serverAddr}/api/v1/projects/${projectId}/forms/${formId}/records/${recordId}`
  // console.log(url)
  return fetch(url, {
    mode: 'cors', // no-cors
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  })
}

export function patchRecordEvent(
  authToken: string,
  projectId: string,
  formId: string,
  recordId: string,
  patch: any
): Promise<Response> {
  let url = `${serverAddr}/api/v1/projects/${projectId}/forms/${formId}/records/${recordId}`
  return fetch(url, {
    method: 'PATCH',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patch),
  })
}
