// TODO: navigation bar render private labels conditionally
import React from 'react'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { Mutex } from 'async-mutex'

import { AuthContextType, useAuthState } from '../../auth/Firebase'
import * as result from '../../domain/base/Result'
import { InvalidStateError } from '../../domain/base/StandardErrors'
import { ActiveRecord } from '../../domain/caserecord/ActiveRecord'
import {
  PrivateLabel,
  LocalStoragePrivateLabels,
} from '../../utils/localstorage/PrivateLabels'
import { fetchRecord, patchRecordEvent } from '../../utils/fetch/FetchRecords'

import {
  OnQuestionChanged,
  OnRecordAction,
  OnUnitChanged,
} from './RecordContainer'
import { PrivateLabelsComponent } from './PrivateLabelsComponent'
import { RichRecordContainer } from './RichRecordContainer'

import '../BaseComponent.css'
import { PageLoadingComponent } from '../projects/PageLoadingComponent'

let patchRecordEventMutex = new Mutex()
const setStateSyncTimeout = 50

interface EditRecordComponentProps {
  record: ActiveRecord
  showCopyDateButton: boolean
  onQuestionChanged: OnQuestionChanged
  onUnitChanged: OnUnitChanged
  onRecordAction: OnRecordAction

  privateLabels: PrivateLabel[]
  setPrivateLabels: (newLabels: PrivateLabel[]) => void
}

function EditRecordComponent(props: EditRecordComponentProps) {
  return (
    <RichRecordContainer
      header={
        <>
          <span className="component-view-header">
            Rekord: {props.record.recordId}
          </span>
          <span className="component-view-header-fineprint">
            ankieta: {props.record.form.display_name}
          </span>
          <span className="component-view-header-fineprint">
            wersja: {props.record.form.version}
          </span>
        </>
      }
      privateLabels={
        <PrivateLabelsComponent
          labels={props.privateLabels}
          setLabels={props.setPrivateLabels}
        />
      }
      record={props.record}
      showCopyDateButton={props.showCopyDateButton}
      onQuestionChanged={props.onQuestionChanged}
      onUnitChanged={props.onUnitChanged}
      onRecordAction={props.onRecordAction}
    />
  )
}

interface RecordLoadingContainerProps {
  authContext: AuthContextType
  projectId: string
  formId: string
  recordId: string
}

interface RecordLoadingContainerState {
  record: ActiveRecord | null
  privateLabels: PrivateLabel[]
  error: string | null
}

// does not have rendering logic, only data fetching logic (record from server and record from local storage)
class RecordLoadingContainer extends React.Component<
  RecordLoadingContainerProps,
  RecordLoadingContainerState
> {
  constructor(props: RecordLoadingContainerProps) {
    super(props)

    this.state = {
      record: null,
      privateLabels: [],
      error: null,
    }
  }

  componentDidMount() {
    this.props.authContext.firebaseUser
      ?.getIdToken()
      .then((token) => {
        const user = this.props.authContext.user!!
        const uid = user.id
        this.loadServerStoredRecord(token)
        this.loadPrivateLabels(uid)
      })
      .catch((error) => {
        this.setState({
          error: error,
        })
      })
  }

  loadServerStoredRecord(authToken: string) {
    const recordPromise = fetchRecord(
      authToken,
      this.props.projectId,
      this.props.formId,
      this.props.recordId
    )
    recordPromise
      .then((response) => response.json())
      .then((response) => {
        if (response['code'] === 'not_found') {
          this.setState({
            record: null,
            error: 'Record not found',
          })
          return
        }

        const recordResult = ActiveRecord.fromJSONString(
          JSON.stringify(response)
        )
        if (result.isErr(recordResult)) {
          console.log('record parse error', recordResult)
          throw recordResult
        }
        console.log(recordResult.value)
        this.setState({
          record: recordResult.value,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          record: null,
          error: error,
        })
      })
  }

  loadPrivateLabels(uid: string) {
    const labels = LocalStoragePrivateLabels.load(
      uid,
      this.props.projectId,
      this.props.formId,
      this.props.recordId
    )
    this.setState({ privateLabels: labels.labels })
  }

  onQuestionChanged(itemRef: string, value: any) {
    const valueChangedEvent = {
      version: this.state.record!!.version + 1,
      action: {
        type: 'value_changed',
        item_id: itemRef,
        new_value: value,
      },
    }
    this.sendRecordEvent(valueChangedEvent)
  }

  onUnitChanged(itemRef: string, unit: string) {
    const unitChangedEvent = {
      version: this.state.record!!.version + 1,
      action: {
        type: 'unit_changed',
        item_id: itemRef,
        new_unit: unit,
      },
    }
    this.sendRecordEvent(unitChangedEvent)
  }

  onRecordAction(action_id: string) {
    const actionRequestdEvent = {
      version: this.state.record!!.version + 1,
      action: {
        type: 'action_requested',
        action_id: action_id,
      },
    }
    this.sendRecordEvent(actionRequestdEvent)
  }

  // Because of the optimistic lock && asynchronous frontend changes,
  // we need to send the record event in a serialized way (otherwise, some of the updates
  // from UI would get rejected due to optimisitc lock error).
  // The UI has no idea of serialization, so it (serialization) happens at the send-to-server moment.
  //
  // The send (patchRecordEvent) operation is locked.
  // Every operation will ignore it's own version of the record and try to load the latest one from the
  // react state.
  // To make sure that operation N+1 is reading properly the state.record.version updated by the operation N,
  // we add extra timeout before N+1 execution.
  sendRecordEvent(recordEvent: any) {
    patchRecordEventMutex.acquire().then((release) => {
      setTimeout(() => {
        this.sendRecordEventLocked(recordEvent, release)
      }, setStateSyncTimeout)
    })
  }

  sendRecordEventLocked(recordEvent: any, release: any) {
    recordEvent.version = this.state.record!!.version + 1

    const firebaseUser = this.props.authContext.firebaseUser
    if (firebaseUser === null) {
      //return Promise.reject('no firebase user')
      console.log('ERORR: firebaseUser is missing')
      release()
      return
    }
    firebaseUser
      .getIdToken()
      .then((authToken) =>
        patchRecordEvent(
          authToken,
          this.props.projectId,
          this.props.formId,
          this.props.recordId,
          recordEvent
        )
      )
      .then((response) => response.json())
      .then((response) => {
        if (response['code'] === 'invalid_request') {
          const message = (response['message'] as string) || ''

          const optimisticLockError =
            message.indexOf('Optimistic lock error') >= 0
          // might happen for "typeahead question types"
          const questionDisabled =
            message.indexOf('Invalid event type=value_changed') >= 0 &&
            message.indexOf('is disabled') >= 0

          if (optimisticLockError || questionDisabled) {
            console.log(response)
            window.alert(
              'Bład synchronizacji danych. Strona odświeży się automatycznie, aby rozwiązać problem'
            )
            document.location.reload()
            release()
            return
          } else {
            //console.log('ups, invalid request', response)
            console.log('invalid request', response)
            release()
            return
          }
        }
        const recordResult = ActiveRecord.fromDict(response)

        if (result.isErr(recordResult)) {
          console.log('record parse error', recordResult)
          release()
          throw recordResult
        }
        console.log(recordResult.value)
        this.setState({
          record: recordResult.value,
        })
        release()
      })
  }

  setPrivateLabels(updatedLabels: PrivateLabel[]) {
    const uid = this.props.authContext.user!!.id

    console.log(`new private labels: ${updatedLabels}`)
    this.setState({
      privateLabels: updatedLabels,
    })

    LocalStoragePrivateLabels.save(
      uid,
      this.props.projectId,
      this.props.formId,
      this.props.recordId,
      { labels: updatedLabels }
    )
  }

  render() {
    const record = this.state.record
    if (record === null) {
      return <PageLoadingComponent />
    }
    let showCopyDateButton = false
    if (this.props.formId === 'rak-gruczolu-krokowego') {
      showCopyDateButton = true
    }

    return (
      <EditRecordComponent
        record={record}
        showCopyDateButton={showCopyDateButton}
        onQuestionChanged={this.onQuestionChanged.bind(this)}
        onUnitChanged={this.onUnitChanged.bind(this)}
        onRecordAction={this.onRecordAction.bind(this)}
        privateLabels={this.state.privateLabels}
        setPrivateLabels={this.setPrivateLabels.bind(this)}
      />
    )
  }
}

export function EditRecordContainer(props: any) {
  let authState = useAuthState()
  let { projectId, formId, recordId } = useParams()
  // console.log(
  //   `templateId=${templateId} templateVersion=${templateVersion}, recordId=${recordId}`
  // )
  if (projectId === null || projectId === undefined) {
    const error = new InvalidStateError('projectId is empty')
    return <div>{error.message}</div>
  }
  if (formId === null || formId === undefined) {
    const error = new InvalidStateError('templateVersion is empty')
    return <div>{error.message}</div>
  }
  if (recordId === null || recordId === undefined) {
    const error = new InvalidStateError('recordId is empty')
    return <div>{error.message}</div>
  }

  return (
    <RecordLoadingContainer
      authContext={authState}
      projectId={projectId}
      formId={formId}
      recordId={recordId}
    />
  )
}
