import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../auth/Firebase'
import { Setting, userNameSetting } from '../../domain/settings/Setting'
import { fetchSettings } from '../../utils/fetch/FetchSettings'
import { PageLoadingComponent } from '../projects/PageLoadingComponent'

import './SettingsContainer.css'

interface SettingsComponentProps {
  settings: Setting[] | null
}

class SettingsComponent extends React.Component<SettingsComponentProps> {
  render() {
    if (this.props.settings === null) {
      return <PageLoadingComponent />
    }
    return (
      <div>
        <div className="settings-container">
          <div className="settings-title">Ustawienia</div>
          <hr />
          <div className="settings-subtitle">Dane użytkownika</div>
          {this.renderSettings(this.props.settings)}
        </div>
      </div>
    )
  }

  renderSettings(settings: Setting[]) {
    return (
      <div>
        {settings.map((setting, index) => {
          const settingId = 'set-' + index
          return (
            <div className="row m-1" key={settingId}>
              <label htmlFor={settingId} className="col-3 col-form-label">
                {setting.display_name}:
              </label>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control"
                  id={settingId}
                  value={setting.value}
                  readOnly={true}
                  disabled={true}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export function SettingsContainer() {
  let authState = useAuthState()
  const [settings, setSettings] = useState<Setting[] | null>(null)

  useEffect(() => {
    // TODO(pawel): this is invalid way of avoiding the useEffect infinite loop.
    // Proper way would be to define the dependency array so that if settings content didn't change
    // (even when the settings array is replaced with the same array), then effect isn't triggered.
    // https://dmitripavlutin.com/react-useeffect-infinite-loop/
    if (settings !== null) {
      return
    }

    authState.firebaseUser
      ?.getIdToken()
      .then((authToken) => fetchSettings(authToken))
      .then((response) => response.json())
      .then((response) => {
        const settings = response.settings
        const username = authState.user?.name ?? ''
        settings.unshift(userNameSetting(username))

        setSettings(settings)
      })
      .catch((error) => {
        console.log('fetchSettings error', error)
      })
  }, [authState.firebaseUser, settings])
  return <SettingsComponent settings={settings} />
}
