type Success<T> = { value: T }

function success<T>(value: T): Success<T> {
  return { value: value }
}

interface Error {
  code: string
  message: string
  retriable: boolean
}

type Result<T, E extends Error> = Success<T> | E

function isSuccess<T, E extends Error>(val: Result<T, E>): val is Success<T> {
  return (val as Success<T>).value !== undefined
}

function isErr<T, E extends Error>(val: Result<T, E>): val is E {
  return (val as Error).code !== undefined
}

function errMsg<T, E extends Error>(val: Result<T, E>): string | null {
  if (!isErr(val)) {
    return null
  }
  return `err{code=${val.code} msg=${val.message}}`
}

export type { Success, Error, Result }
export { success, isSuccess, isErr, errMsg }
