// TODO: navigation bar render private labels conditionally
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useAuthState } from '../../auth/Firebase'
import { createRecord } from '../../utils/fetch/FetchRecords'

import '../BaseComponent.css'
import { PageLoadingComponent } from '../projects/PageLoadingComponent'

export function NewRecordContainer(props: any) {
  let authState = useAuthState()
  let { projectId, formId } = useParams()
  const [recordId, setRecordId] = useState<string | null>(null)

  useEffect(() => {
    // TODO(pawel): this is invalid way of avoiding the useEffect infinite loop.
    // Proper way would be to define the dependency array so that if settings content didn't change
    // (even when the settings array is replaced with the same array), then effect isn't triggered.
    // https://dmitripavlutin.com/react-useeffect-infinite-loop/
    if (projectId === null || projectId === undefined) {
      return
    }
    if (formId === null || formId === undefined) {
      return
    }

    authState.firebaseUser
      ?.getIdToken()
      .then((authToken) => createRecord(authToken, projectId!!, formId!!))
      .then((response) => response.json())
      .then((response) => {
        setRecordId(response.record_id)
      })
      .catch((error) => {
        console.log('createRecord error', error)
      })
  }, [projectId, formId, authState.firebaseUser])

  if (recordId === null || recordId === undefined) {
    return <PageLoadingComponent />
  } else {
    const navigateTo = `/projects/${projectId}/forms/${formId}/records/${recordId}`
    return <Navigate to={navigateTo} replace />
  }
}
