import React from 'react'
import { Link, useParams } from 'react-router-dom'

import * as result from '../../domain/base/Result'
import { fetchFormDescription } from '../../utils/fetch/FetchForms'
import {
  fetchRecords,
  RecordListItem,
  recordListItemFromDict,
} from '../../utils/fetch/FetchRecords'
import { generateRecordId } from '../../utils/IdGenerator'
import { User } from '../../auth/User'
import { AuthContextType, useAuthState } from '../../auth/Firebase'
import { InvalidStateError } from '../../domain/base/StandardErrors'
import { FormDescription } from '../../domain/caserecord/FormDescription'

import {
  LocalStoragePrivateLabels,
  PrivateLabels,
} from '../../utils/localstorage/PrivateLabels'
import { Columns, RecordListTable, RecordTableItem } from './RecordListTable'

import '../BaseComponent.css'
import './RecordListContainer.css'
import { PageLoadingComponent } from '../projects/PageLoadingComponent'

interface RecordListComponentProps {
  projectId: string
  formId: string
  formDescription: FormDescription
  canCreateNewRecord: boolean
  items: RecordItemWithPrivateLabels[]
}

interface RecordItemWithPrivateLabels extends RecordTableItem {
  private_labels: PrivateLabels
}

class RecordListComponent extends React.Component<RecordListComponentProps> {
  newRecordId: string

  constructor(props: RecordListComponentProps) {
    super(props)
    this.newRecordId = generateRecordId()
  }

  render() {
    const template = this.props.formDescription || {}
    const snippets: RecordItemWithPrivateLabels[] = this.props.items
    snippets.sort((a, b) => {
      // descending, latest first
      return b.record_item.updated.getTime() - a.record_item.updated.getTime()
    })

    return (
      <div className="component-view">
        <div>
          <span className="component-view-header">
            Ankieta: {template.display_name}
          </span>
          <span className="component-view-header-fineprint">
            wersja: {template.version}
          </span>
        </div>

        <div className="crlList">
          <div className="crlSection">
            <Link
              to={`/projects/${this.props.projectId}/forms/${this.props.formId}/records/new`}
              className={this.props.canCreateNewRecord ? '' : 'disabled-link'}
            >
              Nowa ankieta
            </Link>
            <span
              className={
                this.props.canCreateNewRecord
                  ? 'display-none'
                  : 'disabled-comment'
              }
            >
              Rekrutacja nowych pacjentów jest tymczasowo zamknięta
            </span>
          </div>
          <div className="crlSection">
            <RecordListTable
              columns={[
                Columns.Order,
                Columns.RecordIdLink(this.props.projectId, this.props.formId),
                Columns.UpdatedProp,
                Columns.PrivateLabels,
              ]}
              data={snippets}
            />
          </div>
        </div>
      </div>
    )
  }
}

interface DataLoadingRecordListComponentProps {
  authContext: AuthContextType
  projectId: string
  formId: string
}

interface DataLoadingRecordListComponentState {
  canCreateNewRecord: boolean
  formDescription: FormDescription | null
  recordItems: Map<string, RecordListItem> | null
  privateLabels: Map<String, PrivateLabels> | null
  error: string | null
}

class DataLoadingRecordListComponent extends React.Component<
  DataLoadingRecordListComponentProps,
  DataLoadingRecordListComponentState
> {
  constructor(props: DataLoadingRecordListComponentProps) {
    super(props)
    this.state = {
      canCreateNewRecord: false,
      formDescription: null,
      recordItems: null,
      privateLabels: null,
      error: null,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    const firebaseUser = this.props.authContext.firebaseUser
    if (firebaseUser === null) {
      throw new Error('internal error: no user to fetch data')
    }

    firebaseUser.getIdToken().then((authToken) => {
      this.populateRoles()
      this.loadFormDescription(authToken, this.props.authContext.user!!)
      this.loadRecordSnippets(authToken)
    })
  }

  populateRoles() {
    let requiredScope = 'researcher:'.concat(
      this.props.projectId,
      '/',
      this.props.formId
    )
    let canCreateNewRecord =
      this.props.authContext.user!!.isAllowedTo(requiredScope) || false
    console.log(canCreateNewRecord)
    console.log(requiredScope)
    console.log(this.props.authContext.user!!.roles)
    this.setState({
      canCreateNewRecord: canCreateNewRecord.valueOf(),
    })
  }

  loadFormDescription(authToken: string, user: User) {
    fetchFormDescription(
      authToken,
      user,
      this.props.projectId,
      this.props.formId
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          formDescription: response as FormDescription,
        })
      })
      .catch((error) => {
        this.setState({
          error: error,
        })
      })
  }

  loadRecordSnippets(authToken: string) {
    const recordSnippetsPromise = fetchRecords(
      authToken,
      this.props.projectId,
      this.props.formId
    )

    recordSnippetsPromise
      .then((response) => response.json())
      .then((response) => {
        const items = new Map<string, RecordListItem>()
        response.records.forEach((rawItem: any) => {
          const recordItem = recordListItemFromDict(rawItem)
          items.set(recordItem.record_id, recordItem)
        })

        this.setState({
          recordItems: items,
        })
      })
      .catch((error) => {
        console.log('fetchServerRecordsSnipppets error', error)
      })
  }

  // returns [localOnly, dirty, server]
  organizeState(): RecordItemWithPrivateLabels[] | null {
    const recordItems = this.state.recordItems
    if (recordItems === null) {
      return null
    }

    let records = new Array<RecordItemWithPrivateLabels>()
    const loadPrivateLabels = (recordId: string): PrivateLabels => {
      const uid = this.props.authContext.user!!.id
      return LocalStoragePrivateLabels.load(
        uid,
        this.props.projectId,
        this.props.formId,
        recordId
      )
    }

    recordItems.forEach((item: RecordListItem) => {
      records.push({
        record_item: item,
        private_labels: loadPrivateLabels(item.record_id),
      })
    })

    return records
  }

  render() {
    const formDescription = this.state.formDescription
    if (formDescription === null) {
      return <PageLoadingComponent />
    }

    const recordItems = this.organizeState()
    if (recordItems === null) {
      return <PageLoadingComponent />
    }

    return (
      <RecordListComponent
        projectId={this.props.projectId}
        formId={this.props.formId}
        formDescription={formDescription}
        canCreateNewRecord={this.state.canCreateNewRecord}
        items={recordItems}
      />
    )
  }
}

export function RecordListContainer(props: any) {
  let authState = useAuthState()
  let { projectId, formId } = useParams()
  if (projectId === null || projectId === undefined) {
    const error = new InvalidStateError('projectId is empty')
    return <div>{error.message}</div>
  }
  if (formId === null || formId === undefined) {
    const error = new InvalidStateError('templateVersion is empty')
    return <div>{error.message}</div>
  }
  return (
    <DataLoadingRecordListComponent
      authContext={authState}
      projectId={projectId}
      formId={formId}
    />
  )
}

/*
Ankeity wrzucone na serwer i niezmienione lokalnie

Ankiety wrzucone na serwer, a następnie zmienione lokalnie

Anikety zapisany tylko lokalnie

Nowa ankieta


Decision if the record is "server-only" or "locally modified" is based on 
version numbers of the server version and the local version.

If server >= local => stored on server, no local changes
If server exists && local >= server => stored on server, local modifications
If no server => local modifications only
*/
