import { Link } from 'react-router-dom'
import { CellProps, Column, useTable, useSortBy } from 'react-table'
import { formatDateTimeHHMM } from '../../utils/Date'
import { RecordListItem } from '../../utils/fetch/FetchRecords'
import { PrivateLabels } from '../../utils/localstorage/PrivateLabels'

import './RecordListTable.css'

export interface RecordTableItem {
  record_item: RecordListItem
}

// columns: ReadonlyArray<Column<D>>;
// data: readonly D[];
export function RecordListTable(props: {
  columns: ReadonlyArray<Column<any>>
  data: readonly any[]
}): JSX.Element {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
    },
    useSortBy
  )

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <table className="table records-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              // <th scope="col" {...column.getHeaderProps()}>
              //   {column.render('Header')}
              // </th>

              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

// Type of the CellProps (as defined in the @types/react-table)
// export type CellProps<D extends object, V = any> = TableInstance<D> & {
//     column: ColumnInstance<D>;
//     row: Row<D>;
//     cell: Cell<D, V>;
//     value: CellValue<V>;
// };
export const Columns = {
  Order: {
    Header: '#',
    accessor: 'rowIndex',
    Cell: (props: CellProps<any, any>) => {
      return <>{props.row.index + 1}</>
    },
  },

  RecordIdLink: (projectId: string, formId: string) => {
    return {
      Header: 'Id rekordu',
      accessor: 'record_item.record_id',
      Cell: (props: CellProps<any, string>) => {
        const recordId = props.cell.value
        return (
          <Link
            to={`/projects/${projectId}/forms/${formId}/records/${recordId}`}
          >
            <span className="crlProp crlPublicProp">{recordId}</span>
          </Link>
        )
      },
    }
  },

  AuditRecordIdLink: (projectId: string, formId: string) => {
    return {
      Header: 'Id rekordu',
      accessor: 'record_id',
      Cell: (props: CellProps<any, string>) => {
        const recordId = props.cell.value
        return (
          <Link to={`/audit/${projectId}/forms/${formId}/records/${recordId}`}>
            <span className="crlProp crlPublicProp">{recordId}</span>
          </Link>
        )
      },
    }
  },

  UpdatedProp: {
    Header: 'Ostatnia modyfikacja',
    accessor: 'record_item.updated',
    sortType: 'datetime',
    Cell: (props: CellProps<any, Date>) => {
      return (
        <span className="crlProp crlPublicProp">
          {formatDateTimeHHMM(props.cell.value)}
        </span>
      )
    },
  },

  Updated: {
    Header: 'Ostatnia modyfikacja',
    accessor: 'updated',
    sortType: 'datetime',
    Cell: (props: CellProps<any, Date>) => {
      return <>{formatDateTimeHHMM(props.cell.value)}</>
    },
  },

  UpdatedBy: {
    Header: 'Zmodyfikowane przez',
    accessor: 'updatedByName',
    Cell: (props: CellProps<any, Date>) => {
      return <>{props.cell.value}</>
    },
  },

  PrivateLabels: {
    Header: 'Prywatne etykiety',
    accessor: 'private_labels',
    Cell: (props: CellProps<any, PrivateLabels>) => {
      return (
        <>
          {props.cell.value.labels.map((pl: any, index: number) => {
            return (
              <span key={index} className="crlProp crlPrivateProp">
                {pl.key}={pl.value}
              </span>
            )
          })}
        </>
      )
    },
  },

  ResearcherName: {
    Header: 'Badacz',
    accessor: 'researcher_name',
    Cell: (props: CellProps<any, Date>) => {
      return <>{props.cell.value}</>
    },
  },
}

export function BooleanColumn(header: string, accessor: string) {
  return {
    Header: header,
    accessor: accessor,
    Cell: (props: CellProps<any, boolean>) => {
      return (
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          checked={props.cell.value}
          readOnly={true}
        />
      )
    },
  }
}
