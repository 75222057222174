import { Navigate, useParams, generatePath } from 'react-router-dom'
import { useAuthState, authLoading } from '../../auth/Firebase'

import './AuthRequired.css'

const AuthRequiredDefaultRedirectPath = '/'

interface AuthRequiredProps {
  // empty if we want "any logged in user"
  scopes: string[]
  redirectTo?: string
  children: React.ReactNode
}

/**
 * AuthRequired protected is placed as Route's element and will protected access
 * to the actual element.
 *
 * AuthRequired expected auth context to be set (useAuthState).
 *
 * AuthRequired will take use route params and use them to evaluate "permScope".
 * e.g. permScope=:projectId/:formId, then for route /projects/acme/forms/abc, the permScope
 * will be evaluated to permScope=acme/abc.
 * Then, permName and evaluated permScope are used to check the user's permissions.
 * If user is not allowed, redirecTo path will be used.
 * If user is allowed, children will be rendrered.
 *
 * Inspired by: https://www.robinwieruch.de/react-router-private-routes/
 * @param props
 * @returns
 */
export function AuthRequired(
  props: React.PropsWithChildren<AuthRequiredProps>
): React.ReactElement {
  // routeParams will be used later in the function, but as react hook it must be placed
  // before any conditional logic
  const routeParams = { ...useParams() }
  // generate path will replace all "*" with whatever value we pass as "*".
  // To keep the "*" in the scope definition, we create identity replacement.
  routeParams['*'] = '*'

  const auth = useAuthState()
  if (authLoading(auth)) {
    return <div className="loading">Trwa ładowanie strony</div>
  }
  if (auth.user === null) {
    const navigateTo = props.redirectTo ?? AuthRequiredDefaultRedirectPath
    return <Navigate to={navigateTo} replace />
  }

  let routeScopes = props.scopes.map((scope) => {
    let routeScope = generatePath(scope, routeParams)
    // ":" will be mached by generatePath - we need some fake value that is replaced by ":" later
    //console.log(`routeScope#1: ${props.scope} + ${routeParams} => ${routeScope}`)
    routeScope = routeScope.replace('#', ':')    
    return routeScope
  })

  //console.log(`routeScope#2: ${props.scope} + ${routeParams} => ${routeScope}`)
  if (props.scopes.length > 0 && !auth.user.isAllowedForAny(routeScopes)) {    
    console.log('auth user not allowed, redirecting', auth)
    console.log(auth.user)
    console.log(routeScopes)
    const navigateTo = props.redirectTo ?? AuthRequiredDefaultRedirectPath
    return <Navigate to={navigateTo} replace />
  }
  // https://stackoverflow.com/questions/58123398/when-to-use-jsx-element-vs-reactnode-vs-reactelement/72353143#72353143
  // The workaround is to use Fragments
  return <>{props.children}</>
}
