import { inspect } from 'util'
import { Result, Error, success, isErr } from '../Result'
import { JsonParsingError } from '../StandardErrors'

export interface TreeNode {
  [key: string]: any // type for unknown keys.
  children?: TreeNode[] // type for a known property.
}

export function parseFromJSON(
  jsonStr: string,
  nodeBuilder: (obj: any, children: TreeNode[]) => TreeNode | null
): Result<TreeNode, JsonParsingError> {
  const raw = JSON.parse(jsonStr)
  return transform(raw, nodeBuilder)
}

export function transform(
  jsonObj: any,
  nodeBuilder: (obj: any, transformedChildren: TreeNode[]) => TreeNode | null
): Result<TreeNode, JsonParsingError> {
  const transformed = transformInternal(jsonObj, nodeBuilder)
  if (isErr(transformed)) {
    return transformed
  }
  if (transformed.value !== null) {
    return success(transformed.value)
  }
  return new InvalidNodeError('top level node returned null from transform')
}

export function transformInternal(
  jsonObj: any,
  nodeBuilder: (obj: any, transformedChildren: TreeNode[]) => TreeNode | null
): Result<TreeNode | null, JsonParsingError> {
  let children: TreeNode[] = []
  const rawChildren = jsonObj['children']
  if (rawChildren !== undefined) {
    const rawChildrenArr = rawChildren as any[]
    for (const rawChild of rawChildrenArr) {
      const transformedChild = transformInternal(rawChild, nodeBuilder)
      if (isErr(transformedChild)) {
        return transformedChild
      } else if (transformedChild.value === null) {
        continue
      } else {
        children.push(transformedChild.value)
      }
    }
  }

  const treeNode = nodeBuilder(jsonObj, children)
  return success(treeNode)
}

export function visit(
  root: TreeNode,
  visitFn: (node: TreeNode) => Result<string, InvalidNodeError>
): Result<string, InvalidNodeError> {
  const rootResult = visitFn(root)
  if (isErr(rootResult)) {
    return rootResult
  }

  if (root.children !== undefined) {
    for (const child of root.children) {
      const childResult = visit(child, visitFn)
      if (isErr(childResult)) {
        return childResult
      }
    }
  }
  return success('')
}

export function buildIndex(
  root: TreeNode,
  idProperty: string = 'id'
): Result<Map<String, TreeNode>, InvalidNodeError> {
  const index = new Map<string, TreeNode>()

  const onNodeVisit = function (
    node: TreeNode
  ): Result<string, InvalidNodeError> {
    // console.log('visiting node', node)
    const id = node[idProperty] as string
    if (id === undefined) {
      return new InvalidNodeError(`missing node id: ${inspect(node)}`)
    }
    if (index.has(id)) {
      return new InvalidNodeError(`duplicate node id: ${id}`)
    }
    index.set(id, node)
    return success('')
  }

  const visitResult = visit(root, onNodeVisit)
  if (isErr(visitResult)) {
    return visitResult
  }
  return success(index)
}

export function nodeAt(root: TreeNode, pos: number[]): TreeNode | null {
  if (pos.length === 0) {
    return root
  }
  if (root.children === undefined || root.children.length <= pos[0]) {
    return null
  }
  return nodeAt(root.children[pos[0]], pos.slice(1))
}

export class InvalidNodeError implements Error {
  code = 'INVALID_NODE_ERROR'
  message: string = ''
  retriable: boolean = false

  constructor(message: string = '') {
    this.message = message
  }
}
