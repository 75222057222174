import React from 'react'
import { fetchHTMLTemplates } from '../../utils/fetch/FetchHTMLTemplates'
import { PageLoadingComponent } from './PageLoadingComponent'

interface StaticHTMLComponentProps {
  htmlPath: string
}

interface StaticHTMLComponentState {
  innerHTML: string | null
}

export class StaticHTMLComponent extends React.Component<
  StaticHTMLComponentProps,
  StaticHTMLComponentState
> {
  constructor(props: StaticHTMLComponentProps) {
    super(props)
    this.state = {
      innerHTML: null,
    }
  }

  componentDidMount() {
    fetchHTMLTemplates(this.props.htmlPath)
      .then((response) => response.text())
      .then((response) => {
        // console.log(response)
        this.setState({
          innerHTML: response,
        })
      })
  }

  render() {
    if (this.state.innerHTML === null) {
      return <PageLoadingComponent />
    }
    return <div dangerouslySetInnerHTML={{ __html: this.state.innerHTML }} />
  }
}
