export class User {
  readonly id: string
  readonly name: string
  readonly roles: Role[]

  constructor(id: string, name: string, roles: Role[]) {
    this.id = id
    this.name = name
    this.roles = roles
  }

  // TODO(pawel): parsing error
  static fromObject(obj: any): User {
    const id = obj['uid']
    const name = obj['name']
    const roles = (obj['roles'] as any[]).map((roleObj) => {
      return new Role(roleObj['scope'])
    })
    return new User(id, name, roles)
  }

  isAllowedTo(scope: string): Boolean {
    return this.roles.findIndex((role: Role) => role.allowsTo(scope)) > -1
  }

  isAllowedForAny(scopes: string[]): Boolean {
    for (var scope of scopes) {
      if (this.isAllowedTo(scope)) {
        return true
      }
    }
    return false
  }
}

export class Role {
  // role:resourceRoot/resourceDetail
  readonly scope: string

  constructor(scope: string) {
    this.scope = scope
  }

  allowsTo(scope: string): Boolean {
    // console.log(`checking allows [role: ${this.scope}] / [scope ${scope}]`)
    const role_tokens = this.scope.split(':')
    const scope_tokens = scope.split(':')

    if (role_tokens.length !== 2 || scope_tokens.length !== 2) {
      return false
    }
    if (role_tokens[0] !== scope_tokens[0]) {
      return false
    }

    const role_details = role_tokens[1].split('/')
    const scope_details = scope_tokens[1].split('/')
    if (role_details.length !== scope_details.length) {
      return false
    }
    for (var i = 0; i < role_details.length; i++) {
      // role_details[i] == "*" -> wildcard
      // scope_details[i] == "*" -> anything is OK
      if (
        role_details[i] !== '*' &&
        scope_details[i] !== '*' &&
        role_details[i] !== scope_details[i]
      ) {
        return false
      }
    }
    return true
  }
}
