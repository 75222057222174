import './FrontpageComponent.css'

export function FrontpageComponent() {
  return (
    <div className="center-screen">
      <div>
        <img
          className="menu-logo"
          src="/static/img/medicea-logo.png"
          alt="Medica logo"
        />
      </div>
      <div>
        <img
          className="menu-logo"
          src="/static/img/hta-registry-logo.png"
          alt="HTA Registry logo"
        />
      </div>
      <div className="ecrf-title">
        ECRF - elektroniczne karty obserwacji klinicznej
      </div>

      <div className="ecrf-contact">
        <div>
          <a href="https://medicea.org.pl">https://medicea.org.pl</a>
        </div>
        <div>email: biuro@medicea.org.pl</div>
      </div>
    </div>
  )
}
