import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuthState } from '../../auth/Firebase'
import * as result from '../../domain/base/Result'
import { InvalidStateError } from '../../domain/base/StandardErrors'
import { ActiveRecord } from '../../domain/caserecord/ActiveRecord'
import { formatDateTimeHHMM } from '../../utils/Date'
import { fetchRecord } from '../../utils/fetch/FetchRecords'
import { PageLoadingComponent } from '../projects/PageLoadingComponent'
import { RichRecordContainer } from '../record/RichRecordContainer'
import { OnQuestionChanged, OnRecordAction, OnUnitChanged } from '../record/RecordContainer'

import './AuditEditRecordContainer.css'

interface RecordAuditMetadata {
  created: Date
  researcherName: string
  updated: Date
  updatedByName: string
}

interface AuditEditRecordComponentProps {
  record: ActiveRecord
  recordMetadata: RecordAuditMetadata
  onQuestionChanged: OnQuestionChanged
  onUnitChanged: OnUnitChanged
  onRecordAction: OnRecordAction
}

function AuditEditRecordComponent(props: AuditEditRecordComponentProps) {
  const metadata = props.recordMetadata
  return (
    <RichRecordContainer
      header={
        <>
          <span className="component-view-header">
            Rekord: {props.record.recordId}
          </span>
          <span className="component-view-header-fineprint">
            ankieta: {props.record.form.display_name}
          </span>
          <span className="component-view-header-fineprint">
            wersja: {props.record.form.version}
          </span>
          <div className="metadata">
            <span>Utworzona przez: </span>
            <span>{metadata.researcherName}</span>
            <span> ({formatDateTimeHHMM(metadata.created)})</span>
          </div>
          <div className="metadata">
            <span>Zmodyfikowana przez: </span>
            <span>{metadata.updatedByName}</span>
            <span> ({formatDateTimeHHMM(metadata.updated)})</span>
          </div>
        </>
      }
      privateLabels={null}
      record={props.record}
      showCopyDateButton={false}
      onQuestionChanged={props.onQuestionChanged}
      onUnitChanged={props.onUnitChanged}
      onRecordAction={props.onRecordAction}
    />
  )
}

export function AuditEditRecordContainer(props: any) {
  let authState = useAuthState()
  let { projectId, formId, recordId } = useParams()

  let [record, setRecord] = useState<ActiveRecord | null>(null)
  let [recordMetadata, setRecordMetadata] =
    useState<RecordAuditMetadata | null>(null)
  let [error, setError] = useState<result.Error | null>(null)

  const checkBasicParams: () => boolean = () => {
    if (
      authState.user === null ||
      projectId === null ||
      projectId === undefined ||
      formId === null ||
      formId === undefined ||
      recordId === null ||
      recordId === undefined
    ) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (!checkBasicParams()) {
      return
    }
    authState.firebaseUser
      ?.getIdToken()
      .then((token) => fetchRecord(token, projectId!!, formId!!, recordId!!))
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        const recordResult = ActiveRecord.fromJSONString(
          JSON.stringify(response)
        )
        if (result.isErr(recordResult)) {
          setError(recordResult)
        } else {
          setRecord(recordResult.value)
          setRecordMetadata({
            created: new Date(response['created']),
            researcherName: response['researcherName'],
            updated: new Date(response['updated']),
            updatedByName: response['updatedByName'],
          })
          setError(null)
        }
      })
      .catch((error) => {
        setRecord(null)
        setError(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.firebaseUser, authState.user, projectId, formId, recordId])

  // console.log(
  //   `templateId=${templateId} templateVersion=${templateVersion}, recordId=${recordId}`
  // )
  if (projectId === null || projectId === undefined) {
    const error = new InvalidStateError('projectId is empty')
    return <div>{error.message}</div>
  }
  if (formId === null || formId === undefined) {
    const error = new InvalidStateError('templateVersion is empty')
    return <div>{error.message}</div>
  }
  if (recordId === null || recordId === undefined) {
    const error = new InvalidStateError('recordId is empty')
    return <div>{error.message}</div>
  }

  if (record === null) {
    return <PageLoadingComponent />
  }

  return (
    <AuditEditRecordComponent
      record={record}
      recordMetadata={recordMetadata!!}
      onQuestionChanged={(itemRef: string, value: any) => {
        console.log('onQuestionChanged', itemRef, value)
        window.alert("Modyfikacja rekordów w trybie audytu nie jest jeszcze zaimplementowana")
      }}
      onUnitChanged={(itemRef: string, unit: any) => {
        console.log('onUnitChanged', itemRef, unit)
        window.alert("Modyfikacja rekordów w trybie audytu nie jest jeszcze zaimplementowana")
      }}
      onRecordAction={(actionId: string) => {
        console.log('onRecordAction', actionId)
        window.alert("Modyfikacja rekordów w trybie audytu nie jest jeszcze zaimplementowana")
      }}
    />
  )
}
