import React from 'react'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import {
  generatePath,
  matchPath,
  Params,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useAuthState } from '../../auth/Firebase'
import { User } from '../../auth/User'
import './MenuComponent.css'

const Paths = {
  FRONTPAGE: '/',

  PROJECTS_LIST: '/projects',
  PROJECT_ROOT: '/projects/:projectId',
  FORMS_LIST: '/projects/:projectId/forms',
  RECORDS_LIST: '/projects/:projectId/forms/:formId/records',
  RECORD_DETAILS: '/projects/:projectId/forms/:formId/records/:recordId',

  AUDIT: '/audit',
  AUDIT_RECORDS_LIST: '/audit/:projectId/forms/:formId/records',
  AUDIT_RECORD_DETAILS: '/audit/:projectId/forms/:formId/records/:recordId',

  ANALYSIS: '/analysis',

  SETTINGS: '/settings',

  LOGIN: '/login',
  LOGOUT: '/logout',
}

const ProjectNamePretty = new Map([
  ['turpol', 'TURPOL'],
  ['uroonkologia', 'Uroonkologia'],
  ['urocapol', 'UROCAPOL'],
])

interface MenuComponentProps {
  user: User | null
  logout: () => void

  navigateTo: (item: string) => void

  locationPath: string
  matchedPath: string
  matchedParams: Params<string>
}

class MenuComponent extends React.Component<MenuComponentProps> {
  render() {
    const handleSelect = (eventKey: any) => {
      if (eventKey === Paths.LOGOUT) {
        this.props.logout()
        this.props.navigateTo(Paths.PROJECTS_LIST)
      } else {
        this.props.navigateTo(eventKey)
      }
    }

    return (
      <Nav className="menu row" onSelect={handleSelect}>
        <div className="col-2 menu-item d-flex align-items-center">
          <img
            className="menu-logo"
            src="/static/img/medicea-logo.png"
            alt="Medica logo"
          />
          <img
            className="menu-logo"
            src="/static/img/hta-registry-logo.png"
            alt="HTA Registry logo"
          />
        </div>
        <div className="col-8 menu-item d-flex align-items-center">
          <div className="logo-divider" />
          {this.renderDropdown(this.props)}
          {this.renderProjectRoot(this.props)}
          {this.renderFormsList(this.props)}
          {this.renderRecordList(this.props)}
          {this.renderRecordDetails(this.props)}

          {this.renderAuditRecordList(this.props)}
          {this.renderAuditRecordDetails(this.props)}
        </div>
        <div className="col-1 menu-item d-flex align-items-center">
          {this.renderSettings(this.props)}
        </div>
        <div className="col-1 menu-item d-flex align-items-center">
          {this.renderLoginOrLogout(this.props)}
        </div>
      </Nav>
    )
  }

  renderDropdown(props: MenuComponentProps) {
    if (props.user === null) {
      return null
    }

    const items = [
      [Paths.PROJECTS_LIST, 'Projekty'],
      [Paths.AUDIT, 'Audyt'],
      [Paths.ANALYSIS, 'Analiza'],
    ]

    let audit = props.user?.isAllowedTo('auditor:*/*')
    if (!audit) {
      items.pop()
      items.pop()
    }

    // ].filter((item, index) => {
    //   return index === 0 || props.user?.isAllowedTo(item[0], null)
    // })
    if (items.length === 1) {
      return (
        <StyledLink
          breadcrumb={false}
          eventKey={items[0][0]}
          loc={props.locationPath}
          text={items[0][1]}
        />
      )
    }

    const path = this.props.locationPath
    let selectedItem = 0
    if (path.startsWith(Paths.AUDIT)) {
      selectedItem = 1
    }
    if (path.startsWith(Paths.ANALYSIS)) {
      selectedItem = 2
    }
    return (
      <NavDropdown
        className="x-nav-drop-active menu-elem"
        title={items[selectedItem][1]}
        id="nav-proj-drop"
      >
        <NavDropdown.Item key={0} eventKey={items[0][0]}>
          {items[0][1]}
        </NavDropdown.Item>
        {items.slice(1).flatMap((item, index) => {
          return [
            <NavDropdown.Divider key={'d' + index} />,
            <NavDropdown.Item key={'i' + index} eventKey={item[0]}>
              {item[1]}
            </NavDropdown.Item>,
          ]
        })}
      </NavDropdown>
    )
  }

  renderProjectRoot(props: MenuComponentProps) {
    const onProjectPath =
      [
        Paths.PROJECT_ROOT,
        Paths.FORMS_LIST,
        Paths.RECORDS_LIST,
        Paths.RECORD_DETAILS,
      ].indexOf(props.matchedPath) > -1
    if (!onProjectPath) {
      return null
    }

    const path = generatePath(Paths.PROJECT_ROOT, props.matchedParams)
    const projectId: string = props.matchedParams.projectId || ''
    const prettyName = ProjectNamePretty.get(projectId) || projectId
    return (
      <div className="">
        <StyledLink
          breadcrumb={true}
          eventKey={path}
          loc={props.locationPath}
          text={prettyName}
        />
      </div>
    )
  }

  renderFormsList(props: MenuComponentProps) {
    const onFormsListPath =
      [Paths.FORMS_LIST, Paths.RECORDS_LIST, Paths.RECORD_DETAILS].indexOf(
        props.matchedPath
      ) > -1
    if (!onFormsListPath) {
      return null
    }

    const path = generatePath(Paths.FORMS_LIST, props.matchedParams)
    return (
      <div className="">
        <StyledLink
          breadcrumb={true}
          eventKey={path}
          loc={props.locationPath}
          text="Ankiety"
        />
      </div>
    )
  }

  capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  renderRecordList(props: MenuComponentProps) {
    const onRecordList =
      [Paths.RECORDS_LIST, Paths.RECORD_DETAILS].indexOf(props.matchedPath) > -1
    if (!onRecordList) {
      return null
    }

    const path = generatePath(Paths.RECORDS_LIST, props.matchedParams)
    // const text = 'Raporty ' + props.matchedParams.formId
    const text = this.capitalizeFirstLetter(
      props.matchedParams.formId!!.replace('-', ' ')
    )
    return (
      <div className="">
        <StyledLink
          breadcrumb={true}
          eventKey={path}
          loc={props.locationPath}
          text={text}
        />
      </div>
    )
  }

  renderRecordDetails(props: any) {
    const onRecordList = [Paths.RECORD_DETAILS].indexOf(props.matchedPath) > -1
    if (!onRecordList) {
      return null
    }

    const path = generatePath(Paths.RECORD_DETAILS, props.matchedParams)
    const text = props.matchedParams.recordId
    return (
      <div className="">
        <StyledLink
          breadcrumb={true}
          eventKey={path}
          loc={props.locationPath}
          text={text}
        />
      </div>
    )
  }

  renderAuditRecordList(props: any) {
    const onAuditRecordList =
      [Paths.AUDIT_RECORDS_LIST, Paths.AUDIT_RECORD_DETAILS].indexOf(
        props.matchedPath
      ) > -1
    if (!onAuditRecordList) {
      return null
    }

    const path = generatePath(Paths.AUDIT_RECORDS_LIST, props.matchedParams)
    const projectId: string = props.matchedParams.projectId || ''
    const projectPrettyName = ProjectNamePretty.get(projectId) || projectId
    const formPrettyName = this.capitalizeFirstLetter(
      props.matchedParams.formId!!.replace('-', ' ')
    )
    const text = `${projectPrettyName} / ${formPrettyName}`
    return (
      <div className="">
        <StyledLink
          breadcrumb={true}
          eventKey={path}
          loc={props.locationPath}
          text={text}
        />
      </div>
    )
  }

  renderAuditRecordDetails(props: any) {
    const onAuditRecordDetails =
      [Paths.AUDIT_RECORD_DETAILS].indexOf(props.matchedPath) > -1
    if (!onAuditRecordDetails) {
      return null
    }

    const path = generatePath(Paths.AUDIT_RECORD_DETAILS, props.matchedParams)
    const text = props.matchedParams.recordId
    return (
      <div className="">
        <StyledLink
          breadcrumb={true}
          eventKey={path}
          loc={props.locationPath}
          text={text}
        />
      </div>
    )
  }

  renderSettings(props: MenuComponentProps) {
    if (props.user === null) {
      return null
    }
    return (
      <StyledLink
        eventKey={generatePath(Paths.SETTINGS, this.props.matchedParams)}
        loc={this.props.locationPath}
        text="Ustawienia"
      />
    )
  }

  renderLoginOrLogout(props: MenuComponentProps) {
    if (props.user === null) {
      return <Nav.Link eventKey={Paths.LOGIN}>Zaloguj</Nav.Link>
    } else {
      return <Nav.Link eventKey={Paths.LOGOUT}>Wyloguj</Nav.Link>
    }
  }
}

function StyledLink(props: any) {
  const active = props.eventKey === props.loc
  return (
    <Nav.Item className="menu-elem">
      {props.breadcrumb ? <span className="bread-span">{'>>'}</span> : null}
      <Nav.Link
        eventKey={props.eventKey}
        className={active ? 'nav-active' : undefined}
      >
        {props.text}
      </Nav.Link>
    </Nav.Item>
  )
}

interface MenuContainerProps {}

export function MenuContainer(props: MenuContainerProps) {
  const auth = useAuthState()
  const navigate = useNavigate()
  const location = useLocation()

  let matchedPath: string = ''
  let matchedParams: Params<string> = {}

  let paths = [
    Paths.PROJECTS_LIST,
    Paths.PROJECT_ROOT,
    Paths.FORMS_LIST,
    Paths.RECORDS_LIST,
    Paths.RECORD_DETAILS,

    Paths.AUDIT,
    Paths.AUDIT_RECORDS_LIST,
    Paths.AUDIT_RECORD_DETAILS,

    Paths.ANALYSIS,

    Paths.SETTINGS,

    Paths.LOGIN,
  ]

  for (const path of paths) {
    const match = matchPath({ path: path, end: true }, location.pathname)
    if (match != null) {
      matchedPath = path
      matchedParams = match.params
    }
  }

  return (
    <MenuComponent
      user={auth.user}
      logout={auth.logout}
      navigateTo={(link: string) => navigate(link)}
      locationPath={location.pathname}
      matchedPath={matchedPath}
      matchedParams={matchedParams}
    />
  )
}
