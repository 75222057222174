import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../../auth/Firebase'
import { StaticHTMLComponent } from './StaticHTMLComponent'

import './ProjectTurpolComponent.css'

export function ProjectTurpolComponent() {
  const auth = useAuthState()
  let navigate = useNavigate()

  const renderFormsList =
    auth.isAuthenticated &&
    (auth.user?.isAllowedTo('researcher:turpol/rak-piersi') ||
      auth.user?.isAllowedTo('researcher_edit_only:turpol/rak-piersi') ||
      auth.user?.isAllowedTo('auditor:turpol/rak-piersi'))

  return (
    <div>
      {renderFormsList ? (
        <button
          className="btn btn-danger turpolFormsLink"
          onClick={() => navigate('/projects/turpol/forms')}
        >
          Przejdź do listy ankiet
        </button>
      ) : null}
      <StaticHTMLComponent htmlPath="turpol.html.tmpl" />
    </div>
  )
}
