import { serverAddr } from './ServerAddr'

export function fetchHTMLTemplates(
  htmlTemplatePath: string
): Promise<Response> {
  let url = `${serverAddr}/static/templates/${htmlTemplatePath}`
  return fetch(url, {
    mode: 'cors', // no-cors
  })
}
