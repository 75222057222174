import React, { ReactElement, ReactNode } from 'react'
import { ResponseType } from '../../domain/caserecord/CRTemplate'
import { ActiveQuestion } from '../../domain/caserecord/ActiveRecord'
import { OnQuestionChanged, OnUnitChanged } from './RecordContainer'
import './QuestionComponent.css'
import { RecordRenderConfig } from './RecordRenderConfig'

const BATCHED_UPDATED_TIMEOUT = 2000

export interface QuestionComponentProps {
  resp: ActiveQuestion
  renderConfig: RecordRenderConfig
  onQuestionChanged: OnQuestionChanged
  onUnitChanged: OnUnitChanged
}

export interface QuestionComponentState {
  immediateValue: any | null
  immediateUnit: string | null
  updateBatchTimeoutId: number
}

// Handling children in typescript: https://blog.logrocket.com/using-react-children-prop-with-typescript/
// https://codeburst.io/a-complete-guide-to-props-children-in-react-c315fab74e7c
export class QuestionComponent extends React.Component<
  QuestionComponentProps & { children?: ReactNode },
  QuestionComponentState
> {
  constructor(props: QuestionComponentProps) {
    super(props)
    let unit = null
    if (props.resp.units != null) {
      unit = props.resp.units[props.resp.selected_unit!!]
    }

    this.state = {
      immediateValue: props.resp.response,
      immediateUnit: unit,
      updateBatchTimeoutId: 0,
    }
  }

  calculateChildrenClassWidth(children: ReactNode | undefined): number {
    if (children === undefined) {
      return 0
    }
    let width = 0

    let childrenCasted = null
    if (Array.isArray(children)) {
      childrenCasted = children as ReactElement[]
    } else {
      childrenCasted = [children as ReactElement]
    }

    childrenCasted.forEach((child) => {
      if (child === null) {
        return
      }
      let cls = child.props.className
      if (cls === 'col-1') {
        width += 1
      }
      if (cls === 'col-2') {
        width += 2
      }
    })
    return width
  }

  calculateChildrenCount(children: ReactNode | undefined): number {
    if (children === undefined) {
      return 0
    }
    if (Array.isArray(children)) {
      return (children as ReactElement[]).length
    } else {
      return 1
    }
  }

  calculateSpecialIndent(metadata: string | null): number {
    if (metadata === 'render:indent-3') {
      return 3
    }
    if (metadata === 'render:indent-2') {
      return 2
    }
    return 0
  }

  calculateResponseClass(
    specialIndentWidth: number,
    childrenWidth: number
  ): string {
    let reduceWidth = specialIndentWidth + childrenWidth
    const resp = this.props.resp
    if (resp.units !== null) {
      reduceWidth += 2
    }
    if (
      resp.response_type === ResponseType.Date &&
      resp.enabled &&
      this.props.renderConfig.copyDateButton
    ) {
      reduceWidth += 2
    }

    let width = 9 - reduceWidth
    return `col-${width}`
  }

  render() {
    const resp = this.props.resp

    return <div className="q">{this.renderTypeSpecific(resp)}</div>
  }

  shouldComponentUpdate?(
    nextProps: Readonly<QuestionComponentProps>,
    nextState: Readonly<any>,
    nextContext: any
  ): boolean {
    return true
  }

  componentDidUpdate(
    prevProps: Readonly<QuestionComponentProps & { children?: ReactNode }>,
    prevState: Readonly<QuestionComponentState>,
    snapshot: any
  ) {
    if (this.props.resp.response !== prevProps.resp.response) {
      this.setState({
        immediateValue: this.props.resp.response,
      })
    }
  }

  componentWillUnmount() {
    if (this.state.updateBatchTimeoutId !== 0) {
      clearTimeout(this.state.updateBatchTimeoutId)
      this.props.onQuestionChanged(
        this.props.resp.id,
        this.state.immediateValue
      )
    }
  }

  renderTypeSpecific(resp: ActiveQuestion) {
    const formId = this.formId()
    const renderValue =
      this.state.immediateValue !== null ? this.state.immediateValue : ''
    const enabled = resp.enabled
    const specialIndent = this.calculateSpecialIndent(resp.metadata)
    const respColClass = this.calculateResponseClass(
      specialIndent,
      this.calculateChildrenClassWidth(this.props.children)
    )

    if (resp.options !== null) {
      if (resp.multiple) {
        return this.renderMultiselectOptions(
          formId,
          resp.question,
          resp.comment,
          specialIndent,
          respColClass,
          resp.options,
          renderValue,
          enabled,
          resp.error
        )
      } else {
        return this.renderOptions(
          formId,
          resp.question,
          resp.comment,
          specialIndent,
          respColClass,
          resp.options,
          renderValue,
          enabled,
          resp.error
        )
      }
    }
    switch (resp.response_type) {
      case ResponseType.ShortText:
        return this.renderShortText(
          formId,
          resp.question,
          resp.comment,
          specialIndent,
          respColClass,
          renderValue,
          enabled,
          resp.error
        )
      case ResponseType.FreeText:
        return this.renderFreeText(
          formId,
          resp.question,
          resp.comment,
          specialIndent,
          respColClass,
          renderValue,
          enabled,
          resp.error
        )
      case ResponseType.Number:
        return this.renderNumber(
          formId,
          resp.question,
          resp.comment,
          specialIndent,
          respColClass,
          renderValue,
          enabled,
          resp.units,
          resp.selected_unit,
          resp.error
        )
      case ResponseType.Date:
        return this.renderDate(
          formId,
          resp.question,
          resp.comment,
          specialIndent,
          respColClass,
          renderValue,
          enabled,
          resp.error
        )
    }
  }

  renderMultiselectOptions(
    formId: string,
    question: string,
    comment: string | null,
    specialIndent: number,
    respColClass: string,
    options: string[],
    values: string[],
    enabled: boolean,
    error: string | null
  ) {
    return (
      <div className="row m-1">
        {this.maybeRenderIndent(specialIndent)}
        {this.renderLabel(formId, question, comment)}
        <div className={respColClass}>
          {options?.map((option, index) => {
            const optionKey = `${formId}-${index}`
            const checked = values.includes(option)
            return (
              <div
                className="form-check form-check-inline q-top-padding"
                key={index}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={checked}
                  id={optionKey}
                  value={option}
                  onChange={this.onCheckboxChange.bind(this)}
                  disabled={!enabled}
                />
                <label className="form-check-label" htmlFor={optionKey}>
                  {option}
                </label>
              </div>
            )
          })}
          {this.renderError(error)}
        </div>
        {this.props.children}
      </div>
    )
  }

  onCheckboxChange(event: any) {
    const value = event.target.value
    const checked = event.target.checked

    let values = (this.props.resp.response || []) as any[]
    if (checked) {
      values.push(value)
      values.sort((a, b) => {
        const aIndex = this.props.resp.options?.indexOf(a) || -1
        const bIndex = this.props.resp.options?.indexOf(b) || -1
        return aIndex - bIndex
      })
    } else {
      const valueIndex = values.indexOf(value)
      if (valueIndex > -1) {
        values.splice(valueIndex, 1)
      }
    }

    this.setState({
      immediateValue: values,
    })
    this.props.onQuestionChanged(this.props.resp.id, values)
  }

  renderOptions(
    formId: string,
    question: string,
    comment: string | null,
    specialIndent: number,
    respColClass: string,
    qOptions: string[],
    value: string,
    enabled: boolean,
    error: string | null
  ) {
    // we want empty value as be the default option - nothing is selected yet
    let options = [''].concat(qOptions || [])

    return (
      <div className="row m-1">
        {this.maybeRenderIndent(specialIndent)}
        {this.renderLabel(formId, question, comment)}
        <div className={respColClass}>
          <select
            id={formId}
            className={this.getFormSelectClassName()}
            onChange={this.onChangeEvent.bind(this)}
            value={value}
            disabled={!enabled}
          >
            {options.map((option, index) => {
              return (
                <option key={index} value={option} disabled={!enabled}>
                  {option}
                </option>
              )
            })}
          </select>
          {this.renderError(error)}
        </div>
        {this.props.children}
      </div>
    )
  }

  onChangeEvent(event: any) {
    let val = event.target.value
    if (typeof val === 'string' && val === '') {
      val = null
    }
    //this.props.resp.response = val
    this.setState({
      immediateValue: val,
    })
    this.props.onQuestionChanged(this.props.resp.id, val)
  }

  onChangeBatched(event: any) {
    if (this.state.updateBatchTimeoutId !== 0) {
      clearTimeout(this.state.updateBatchTimeoutId)
    }

    // start a timeout operation - after it expires, sent an event with
    // whatever we have as the current state
    const timeToSendBatch = () => {
      this.props.onQuestionChanged(
        this.props.resp.id,
        this.state.immediateValue
      )
    }
    const timeoutId = window.setTimeout(
      timeToSendBatch.bind(this),
      BATCHED_UPDATED_TIMEOUT
    )

    // update the current state, so that the timeout will send the right value
    // to the server.
    // Also, update
    const value = event.target.value
    this.setState({
      immediateValue: value,
      updateBatchTimeoutId: timeoutId,
    })
  }

  onUnitChange(event: any) {
    let val = event.target.value

    this.setState({
      immediateUnit: val,
    })
    this.props.onUnitChanged(this.props.resp.id, val)
  }

  renderShortText(
    formId: string,
    question: string,
    comment: string | null,
    specialIndent: number,
    respColClass: string,
    value: any,
    enabled: boolean,
    error: string | null
  ) {
    return (
      <div className="row m-1">
        {this.maybeRenderIndent(specialIndent)}
        {this.renderLabel(formId, question, comment)}
        <div className={respColClass}>
          <input
            type="text"
            className={this.getFormControlClassName()}
            id={formId}
            onChange={this.onChangeBatched.bind(this)}
            value={value}
            readOnly={!enabled}
            disabled={!enabled}
          />
          {this.renderError(error)}
        </div>
        {this.props.children}
      </div>
    )
  }

  renderFreeText(
    formId: string,
    question: string,
    comment: string | null,
    specialIndent: number,
    respColClass: string,
    value: any,
    enabled: boolean,
    error: string | null
  ) {
    return (
      <div className="row m-1">
        {this.maybeRenderIndent(specialIndent)}
        {this.renderLabel(formId, question, comment)}
        <div className={respColClass}>
          <textarea
            className={this.getFormControlClassName()}
            id={formId}
            onChange={this.onChangeBatched.bind(this)}
            value={value}
            disabled={!enabled}
            readOnly={!enabled}
          />
          {this.renderError(error)}
        </div>
        {this.props.children}
      </div>
    )
  }

  renderNumber(
    formId: string,
    question: string,
    comment: string | null,
    specialIndent: number,
    respColClass: string,
    value: any,
    enabled: boolean,
    units: string[] | null,
    selected_unit: number | null,
    error: string | null
  ) {
    return (
      <div className="row m-1">
        {this.maybeRenderIndent(specialIndent)}
        {this.renderLabel(formId, question, comment)}
        <div className={respColClass}>
          <input
            type="number"
            className={this.getFormControlClassName()}
            id={formId}
            onChange={this.onChangeBatched.bind(this)}
            value={value}
            disabled={!enabled}
            readOnly={!enabled}
          />
          {this.renderError(error)}
        </div>
        {this.renderUnits(formId, units)}
        {this.props.children}
      </div>
    )
  }

  renderUnits(formId: string, units: string[] | null) {
    if (units === null) {
      return null
    }
    if (this.state.immediateUnit == null) {
      return null
    }

    if (units.length === 1) {
      return (
        <div className="col-2">
          <input
            type="text"
            id={formId + '-unit'}
            className={this.getFormControlClassName()}
            value={units[0]}
            disabled={true}
          ></input>
        </div>
      )
    } else {
      return (
        <div className="col-2">
          <select
            id={formId + '-unit'}
            className={this.getFormSelectClassName()}
            onChange={this.onUnitChange.bind(this)}
            value={this.state.immediateUnit}
            disabled={false}
          >
            {units.map((option, index) => {
              return (
                <option key={index} value={option} disabled={false}>
                  {option}
                </option>
              )
            })}
          </select>
        </div>
      )
    }
  }

  renderDate(
    formId: string,
    question: string,
    comment: string | null,
    specialIndent: number,
    respColClass: string,
    value: any,
    enabled: boolean,
    error: string | null
  ) {
    return (
      <div className="row m-1">
        {this.maybeRenderIndent(specialIndent)}
        {this.renderLabel(formId, question, comment)}
        <div className={respColClass}>
          <input
            type="date"
            lang="pl-PL"
            className={this.getFormControlClassName()}
            id={formId}
            onChange={this.onChangeBatched.bind(this)}
            value={value}
            disabled={!enabled}
            readOnly={!enabled}
          />
          {this.renderError(error)}
        </div>
        {this.maybeRenderCopyDateButton(
          enabled,
          value !== undefined && value !== null && value !== '',
          'Kopiuj datę',
          () => {
            this.props.renderConfig.setCachedDate(value)
          }
        )}
        {this.maybeRenderCopyDateButton(
          enabled,
          this.props.renderConfig.cachedDate !== null,
          'Wklej datę',
          () => {
            this.props.onQuestionChanged(
              this.props.resp.id,
              this.props.renderConfig.cachedDate
            )
          }
        )}
        {this.props.children}
      </div>
    )
  }

  private maybeRenderCopyDateButton(
    valueEnabled: boolean,
    copyButtonEnabled: boolean,
    text: string,
    onClick: () => void
  ) {
    if (this.props.renderConfig.copyDateButton === false) {
      return null
    }
    if (!valueEnabled) {
      return null
    }
    const btnEnabled = copyButtonEnabled
    let btnClass = 'btn btn-outline-secondary w100'

    return (
      <div className="col-1">
        <button disabled={!btnEnabled} onClick={onClick} className={btnClass}>
          {text}
        </button>
      </div>
    )
  }

  private getFormControlClassName() {
    let vanilla = 'form-control'
    if (!this.shouldRenderError(this.props.resp.error)) {
      return vanilla
    } else {
      return vanilla + ' is-invalid'
    }
  }

  private getFormSelectClassName() {
    let vanilla = 'form-select'
    if (!this.shouldRenderError(this.props.resp.error)) {
      return vanilla
    } else {
      return vanilla + ' is-invalid'
    }
  }

  private maybeRenderIndent(indent: number) {
    if (indent === 0) {
      return null
    } else if (indent === 1) {
      return <div className="col-1"></div>
    } else if (indent === 2) {
      return <div className="col-2"></div>
    } else {
      return <div className="col-3"></div>
    }
  }

  private renderLabel(
    formId: string,
    question: string,
    comment: string | null
  ) {
    return (
      <label htmlFor={formId} className="col-3 col-form-label">
        {this.labelText(question)}
        {comment != null ? <div className="q-comment">{comment}</div> : null}
      </label>
    )
  }

  private labelText(question: string): string {
    let labelText = question
    if (question.charAt(question.length - 1) !== '?') {
      labelText += ':'
    }
    return labelText
  }

  formId(): string {
    //return this.props.resp.pos.join('-')
    return this.props.resp.id
  }

  renderError(error: string | null): React.ReactNode {
    if (!this.shouldRenderError(error)) {
      return null
    }
    return <div className="err">{error}</div>
  }

  shouldRenderError(error: string | null): boolean {
    if (error === undefined || error === null || error.length === 0) {
      return false
    }
    if (
      !this.props.renderConfig.showWarningsOnEmpty &&
      error === 'Pole nie może być puste'
    ) {
      return false
    }
    return true
  }
}
