import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../../auth/Firebase'
import { StaticHTMLComponent } from './StaticHTMLComponent'

import './ProjectUrocapolComponent.css'
import { User } from '../../auth/User'

export function ProjectUrocapolComponent() {
  const auth = useAuthState()
  let navigate = useNavigate()

  const renderFormsList =
    auth.isAuthenticated && shouldRenderFormList(auth.user)

  return (
    <div>
      {renderFormsList ? (
        <button
          className="btn btn-danger turpolFormsLink"
          onClick={() =>
            navigate('/projects/urocapol/forms/rak-pecherza/records')
          }
        >
          Przejdź do listy ankiet
        </button>
      ) : null}
      <StaticHTMLComponent htmlPath="urocapol.html.tmpl" />
    </div>
  )
}

function shouldRenderFormList(user: User | null): boolean {
  if (user === null) {
    return false
  }
  if (user.isAllowedTo('researcher:urocapol/rak-pecherza')) {
    return true
  }
  if (user.isAllowedTo('researcher_edit_only:urocapol/rak-pecherza')) {
    return true
  }
  if (user.isAllowedTo('auditor:urocapol/rak-pecherza')) {
    return true
  }
  return false
}
