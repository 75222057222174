import { inspect } from 'util'

import { ParsingError, ResponseType } from './CRTemplate'
import {
  InvalidNodeError,
  TreeNode,
  visit,
  buildIndex,
  transform,
} from '../base/tree/TreeOperator'
import * as result from '../base/Result'
import { JsonParsingError } from '../base/StandardErrors'

import { FormDescription } from './FormDescription'

export class ActiveRecord {
  readonly recordId: string
  readonly form: FormDescription
  version: number

  created: Date
  updated: Date

  researcher_id: string

  readonly root: ActiveSection

  readonly actions: Map<string, ActionDef[]>

  private readonly childrenIndex: Map<string, ActiveItem>

  constructor(
    recordId: string,
    form: FormDescription,
    version: number,
    researcher_id: string,
    root: ActiveSection,
    actions: ActionDef[]
  ) {
    this.recordId = recordId
    this.form = form
    this.version = version

    const now = new Date()
    this.created = now
    this.updated = now

    this.researcher_id = researcher_id

    this.root = root

    this.actions = new Map<string, ActionDef[]>()
    for (var actionDef of actions) {
      const position_ref_id = actionDef.position_ref_id
      if (this.actions.has(position_ref_id)) {
        this.actions.get(position_ref_id)!!.push(actionDef)
      } else {
        this.actions.set(position_ref_id, [actionDef])
      }
    }

    this.childrenIndex = ActiveRecord.buildChildrenIndex(root)
  }

  private static buildChildrenIndex(root: ActiveItem): Map<string, ActiveItem> {
    const index = buildIndex(root)
    if (result.isErr(index)) {
      throw index
    }
    return index.value as Map<string, ActiveItem>
  }

  getItemById(itemId: string): ActiveItem | null {
    const item = this.childrenIndex.get(itemId)
    return item !== undefined ? item : null
  }

  private static jsonToRecordTransformer(
    obj: any,
    transformedChildren: TreeNode[]
  ): TreeNode {
    const objType = obj['type']
    if (objType === undefined || (objType !== 'sec' && objType !== 'q')) {
      throw new InvalidNodeError(
        `invalid ActiveRecordq node from JSON: type=${objType} ${inspect(obj)}`
      )
    }

    if (objType === 'sec') {
      const id = obj['id']
      const template_ref = obj['template_ref'] as string
      const title = obj['title'] as string
      let collapsible = obj['collapsible'] as boolean
      if (collapsible === undefined) {
        collapsible = false
      }
      let collapsed = obj['collapsed'] as boolean
      if (collapsed === undefined) {
        collapsed = false
      }
      return new ActiveSection(
        id,
        template_ref,
        title,
        transformedChildren as ActiveItem[],
        collapsible,
        collapsed
      )
    }

    if (objType === 'q') {
      const questionResult = ActiveQuestion.fromJSONObj(obj)
      if (result.isErr(questionResult)) {
        throw questionResult
      }
      return questionResult.value
    }
    throw new InvalidNodeError(`unreachanble node code: ${inspect(obj)}`)
  }

  static fromJSONString(
    jsonString: string
  ): result.Result<ActiveRecord, JsonParsingError> {
    const raw = JSON.parse(jsonString)
    return this.fromDict(raw)
  }

  static fromDict(raw: any): result.Result<ActiveRecord, JsonParsingError> {
    const rootResult = transform(
      raw['root'],
      ActiveRecord.jsonToRecordTransformer
    )
    if (result.isErr(rootResult)) {
      return rootResult
    }

    // now, as we have the root, it's time to extract the top-level record properties
    const recordId = raw['record_id'] as string
    const template = raw['form'] as FormDescription
    const version = raw['version'] as number
    const researcher_id = raw['researcher_id'] as string
    const actions = raw['actions'] as ActionDef[]
    const record = new ActiveRecord(
      recordId,
      template,
      version,
      researcher_id,
      rootResult.value as ActiveSection,
      actions
    )
    record.created = raw['created'] as Date
    record.updated = raw['updated'] as Date

    return result.success(record)
  }

  completionStatus(): [number, number] {
    let completed = 0
    let total = 0

    visit(this.root, function (node: TreeNode): result.Result<
      string,
      InvalidNodeError
    > {
      if (node.type === 'q') {
        const question = node as ActiveQuestion
        if (question.enabled) {
          const thisCompleted = question.response !== null ? 1 : 0
          total += 1
          completed += thisCompleted
        }
      }
      return result.success('ok')
    })

    return [completed, total]
  }
}

export type ActiveItem = ActiveSection | ActiveQuestion

export class ActiveSection {
  public static readonly Type = 'sec'

  readonly type: string = ActiveSection.Type
  readonly id: string
  readonly template_ref: string

  readonly title: string
  readonly children: ActiveItem[]

  readonly collapsible: boolean = false
  readonly collapsed: boolean = false

  constructor(
    id: string,
    template_ref: string,
    title: string,
    children: ActiveItem[],
    collapsible: boolean = false,
    collapsed: boolean = false
  ) {
    this.id = id
    // TODO(pawel): deep copy?
    this.template_ref = template_ref
    this.title = title
    this.children = children
    this.collapsible = collapsible
    this.collapsed = collapsed
  }
}

export class ActiveQuestion {
  public static readonly Type = 'q'

  readonly type: string = ActiveQuestion.Type
  readonly id: string
  readonly template_ref: string

  readonly question: string
  readonly comment: string | null

  readonly response_type: ResponseType
  readonly options: any[] | null
  multiple: boolean = false

  response: any | null

  units: string[] | null
  selected_unit: number | null

  enabled: boolean = true
  calculated: boolean = false

  error: string | null

  readonly metadata: string | null


  constructor(
    id: string,
    template_ref: string,
    question: string,
    comment: string | null,
    response_type: ResponseType,
    options: any[] | null,
    multiple: boolean = false,
    response: any | null,
    units: string[] | null | undefined,
    selected_unit: number | null | undefined,
    enabled: boolean = true,
    calculated: boolean = false,
    error: string | null,
    metadata: string | null
  ) {
    this.id = id
    this.template_ref = template_ref

    this.question = question
    this.comment = comment

    this.response_type = response_type
    this.options = options
    this.multiple = multiple

    this.response = response
    if (units === undefined) {
      this.units = null
    } else {
      this.units = units
    }
    if (selected_unit === undefined) {
      this.selected_unit = null
    } else {
      this.selected_unit = selected_unit
    }

    this.enabled = enabled
    this.calculated = calculated
    this.error = error

    this.metadata = metadata
  }

  static fromJSONObj(
    jsonObj: any
  ): result.Result<ActiveQuestion, JsonParsingError> {
    const objType = jsonObj['type']
    if (objType === undefined || objType !== 'q') {
      throw new InvalidNodeError(
        `invalid ActiveRecord node from JSON: type=${objType} ${inspect(
          jsonObj
        )}`
      )
    }

    const id = jsonObj['id'] as string

    if (id === 'badania-biochemiczne-psa-ang-doubling-time~1') {
      console.log(jsonObj)
    }

    const template_ref = jsonObj['template_ref'] as string

    const question = jsonObj['question'] as string
    let comment = null
    if ('comment' in jsonObj) {
      comment = jsonObj['comment'] as string
    }

    let response_type = ResponseType.ShortText
    if (jsonObj['response_type'] === 'ShortText') {
      response_type = ResponseType.ShortText
    } else if (jsonObj['response_type'] === 'FreeText') {
      response_type = ResponseType.FreeText
    } else if (jsonObj['response_type'] === 'Date') {
      response_type = ResponseType.Date
    } else if (jsonObj['response_type'] === 'Number') {
      response_type = ResponseType.Number
    } else {
      return new ParsingError(
        `q: ${id}, unrecognized respone type: ${jsonObj['response_type']}`
      )
    }
    let options = null
    if ('options' in jsonObj) {
      options = jsonObj['options'] as any[]
    }
    let multiple = false
    if ('multiple' in jsonObj) {
      multiple = jsonObj['multiple'] as boolean
    }

    let response: any | null = null
    if ('response' in jsonObj) {
      response = jsonObj['response']
    }

    let units = null
    if ('units' in jsonObj) {
      units = jsonObj['units'] as string[]
    }
    let selected_unit = null
    if ('selected_unit' in jsonObj) {
      selected_unit = jsonObj['selected_unit'] as number
    }

    let enabled: boolean = true
    if ('enabled' in jsonObj) {
      enabled = jsonObj['enabled']
    }
    let calculated: boolean = false
    if ('calculated' in jsonObj) {
      calculated = jsonObj['calculated']
    }

    let error: string | null = null
    if ('error' in jsonObj) {
      error = jsonObj['error']
    }

    let metadata: string | null = null
    if ('metadata' in jsonObj) {
      metadata = jsonObj['metadata']
    }

    const aq = new ActiveQuestion(
      id,
      template_ref,
      question,
      comment,
      response_type,
      options,
      multiple,
      response,
      units,
      selected_unit,
      enabled,
      calculated,
      error,
      metadata
    )
    return result.success(aq)
  }
}

export const PositionDirRightFrom = 'right_from'
export const PositionDirAfter = 'after'

export interface ActionDef {
  id: string
  template_ref: string
  button_text: string
  button_type: string
  button_group: number | undefined
  // either "right_from" or "after"
  position_dir: string
  position_ref_id: string
}
