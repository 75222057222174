export const CharsetUppercase: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const CharsetLowercase: string = 'abcdefghijklmnopqrstuvwxyz'
export const CharsetNumbers: string = '0123456789'
export const CharsetUppercaseAndNumbers = CharsetUppercase + CharsetNumbers

export function generateId(length: number, charset: string) {
  var result = ''
  var charactersLength = charset.length
  for (var i = 0; i < length; i++) {
    result += charset.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function generatePatientId() {
  const patientId =
    'PT-' + generateId(2, CharsetUppercase) + generateId(6, CharsetNumbers)
  return patientId
}

export function generateRecordId() {
  const patientId =
    'MR-' + generateId(2, CharsetUppercase) + generateId(6, CharsetNumbers)
  return patientId
}
