import React from 'react'
import { Link } from 'react-router-dom'

import '../BaseComponent.css'
import './AuditProjectsListComponent.css'

export class AuditProjectsListComponent extends React.Component {
  render() {
    return (
      <div className="component-view">
        <div className="component-view-header">Audyt projektów</div>
        <ul>
          <li className="audit-proj-item">
            <div className="audit-proj-name">TURPOL</div>
            <ul>
              <li>
                <Link to={`/audit/turpol/forms/rak-piersi/records`}>
                  Rak piersi
                </Link>
              </li>
            </ul>
          </li>
          <li className="audit-proj-item">
            <div className="audit-proj-name">Uroonkologia</div>
            <ul>
              <li>Rak jądra (w budowie)</li>
              <li>Rak nerki (w budowie)</li>
              <li>
                <Link
                  to={`/audit/uroonkologia/forms/rak-gruczolu-krokowego/records`}
                >
                  Rak gruczołu krokowego
                </Link>
                <span> (w budowie)</span>
              </li>

              <li>Rak pęcherza</li>
          </ul>
          </li>
        </ul>
      </div>
    )
  }
}
