import { Error } from './Result'

export class InvalidStateError implements Error {
  code = 'INVALID_STATE_ERROR'
  message: string = ''
  retriable: boolean = false

  constructor(message: string = '') {
    this.message = message
  }
}

export class JsonParsingError implements Error {
  code = 'JSON_PARSING_ERROR'
  message: string = ''
  retriable: boolean = false

  constructor(message: string = '') {
    this.message = message
  }
}
