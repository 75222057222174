export function unixTimestamp(): number {
  return unixTimestampFromDate(new Date())
}

export function unixTimestampFromDate(d: Date) {
  return Math.floor(d.getTime() / 1000)
}

export function dateFromUnixTimestamp(unix: number): Date {
  return new Date(unix * 1000)
}

export function formatDateYYYYMMDD(d: Date): string {
  const dayFormat = [
    padLeft(d.getFullYear()),
    // month are returned wtih 0-based index
    padLeft(d.getMonth() + 1),
    // getDate returns day-of-month in local time. sic!
    padLeft(d.getDate()),
  ].join('-')
  return dayFormat
}

export function formatDateTimeHHMM(d: Date): string {
  const dayFormat = [
    padLeft(d.getFullYear()),
    // month are returned wtih 0-based index
    padLeft(d.getMonth() + 1),
    // getDate returns day-of-month in local time. sic!
    padLeft(d.getDate()),
  ].join('-')

  const timeFormat = [padLeft(d.getHours()), padLeft(d.getMinutes())].join(':')

  return dayFormat + ' ' + timeFormat
}

function padLeft(val: number, base: number = 10, padding: string = '0') {
  // extra len, becasue we're effectively joining empty values with padding
  // - we count 'spaces' between len, not actual len
  var len = String(base || 10).length - String(val).length + 1
  return len > 0 ? new Array(len).join(padding || '0') + val : val
}
