export interface Setting {
  name: string
  display_name: string
  value: string
}

export function userNameSetting(userName: string): Setting {
  return {
    name: 'name',
    display_name: 'Imię i nazwisko',
    value: userName,
  }
}
