import React from 'react'

// Global ID for the element.
const ELEMENT_ID = 'firebaseui_container'

// Promise that resolves unless the FirebaseUI instance is currently being deleted.
let firebaseUiDeletion = Promise.resolve()

/**
 * Properties types.
 */
interface FirebaseAuthProps {
  // The Firebase UI Web UI Config object.
  // See: https://github.com/firebase/firebaseui-web#configuration
  uiConfig: Object
  // The Firebase App auth instance to use.
  firebaseAuth: Object
  // Callback that will be passed the FirebaseUi instance before it is
  // started. This allows access to certain configuration options such as
  // disableAutoSignIn().
  uiCallback?: Function
  className?: String
}

/**
 * React Component wrapper for the FirebaseUI Auth widget.
 */
export default class FirebaseAuth extends React.Component<FirebaseAuthProps> {
  uiConfig: any
  firebaseAuth: any
  uiCallback?: Function
  className?: string
  unregisterAuthObserver: () => void

  firebaseUiWidget?: any
  userSignedIn = false

  /**
   * Constructor  Firebase Auth UI component
   *
   * @param {Object} props - Additional object properties.
   * @constructor
   */
  constructor(props: any) {
    super(props)

    this.uiConfig = props.uiConfig
    this.firebaseAuth = props.firebaseAuth
    this.className = props.className
    this.uiCallback = props.uiCallback
    this.unregisterAuthObserver = () => {}
  }

  /**
   * @inheritDoc
   */
  componentDidMount() {
    // Import the css only on the client.
    require('firebaseui/dist/firebaseui.css')

    // Firebase UI only works on the Client. So we're loading the package in `componentDidMount`
    // So that this works when doing server-side rendering.
    //
    // Firebase UI react package does not have localization built-in.
    // To fix this, I copied the content of the react package (this file),
    // and replaced the firebaseui dependency to Polish (below).
    // Component code: https://github.com/firebase/firebaseui-web-react/blob/master/src/FirebaseAuth.jsx
    // Instruction to build local version: https://github.com/firebase/firebaseui-web#building-firebaseui
    //     after building, I created new node_module directory (firebaseui-pl) and copies the npm__pl as npm.js
    const firebaseui = require('firebaseui-pl/npm')

    // Wait in case the firebase UI instance is being deleted.
    // This can happen if you unmount/remount the element quickly.
    return firebaseUiDeletion.then(() => {
      // Get or Create a firebaseUI instance.
      this.firebaseUiWidget =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(this.firebaseAuth)
      if (this.uiConfig.signInFlow === 'popup') {
        this.firebaseUiWidget.reset()
      }

      // We track the auth state to reset firebaseUi if the user signs out.
      this.userSignedIn = false
      this.unregisterAuthObserver = this.firebaseAuth.onAuthStateChanged(
        (user: any) => {
          if (!user && this.userSignedIn) {
            this.firebaseUiWidget.reset()
          }
          this.userSignedIn = !!user
        }
      )

      // Trigger the callback if any was set.
      if (this.uiCallback) {
        this.uiCallback(this.firebaseUiWidget)
      }

      // Render the firebaseUi Widget.
      this.firebaseUiWidget.start('#' + ELEMENT_ID, this.uiConfig)
    })
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    firebaseUiDeletion = firebaseUiDeletion.then(() => {
      this.unregisterAuthObserver()
      return this.firebaseUiWidget.delete()
    })
    return firebaseUiDeletion
  }

  /**
   * @inheritDoc
   */
  render() {
    return <div className={this.className} id={ELEMENT_ID} />
  }
}
