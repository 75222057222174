import React from 'react'
import {
  deleteItemFromLocalStorage,
  listLocalStorageKeys,
  loadItemFromLocalStorage,
} from '../../../utils/localstorage/LocalStorage'

import './LocalStorageComponent.css'

interface LocalStorageComponentProps {}

interface LocalStorageComponentState {
  expanded: string
  mouseOver: string
}

export class LocalStorageComponent extends React.Component<
  LocalStorageComponentProps,
  LocalStorageComponentState
> {
  constructor(props: LocalStorageComponentProps) {
    super(props)
    this.state = {
      expanded: '',
      mouseOver: '',
    }
  }

  render() {
    // order the keys list using the recordId (if it's there)
    const localKeys = listLocalStorageKeys().sort((a: string, b: string) => {
      const aTokens = a.split('#')
      const bTokens = b.split('#')
      if (aTokens.length === 4 && bTokens.length === 4) {
        return aTokens[3].localeCompare(bTokens[3])
      }
      return a.localeCompare(b)
    })
    return (
      <div className="local-storage-container">
        {localKeys.map((key) => {
          return this.renderStorageItem(key)
        })}
      </div>
    )
  }

  renderStorageItem(key: string) {
    let keyClassName = 'col-10'
    if (key === this.state.mouseOver) {
      keyClassName += ' local-storage-mouse-over'
    }

    return (
      <div
        className="local-storage-item row"
        key={key}
        onMouseOver={() => this.markOver(key)}
        onMouseLeave={() => this.markOver('')}
        onClick={() => this.toggleExpanded(key)}
      >
        <div className="col-2">
          <button
            className="btn-danger float-right"
            onClick={() => this.deleteKey(key)}
          >
            usuń ten element
          </button>
        </div>
        <div className={keyClassName}>{key}</div>
        {this.renderIfExpanded(key)}
      </div>
    )
  }

  renderIfExpanded(key: string) {
    if (this.state.expanded !== key) {
      return null
    }
    const keyData = loadItemFromLocalStorage(key)
    const pretty = JSON.stringify(JSON.parse(keyData), null, 2)
    return <pre className="col-12">{pretty}</pre>
  }

  markOver(key: string) {
    this.setState({ mouseOver: key })
  }

  toggleExpanded(key: string) {
    if (key === this.state.expanded) {
      this.setState({ expanded: '' })
    } else {
      this.setState({ expanded: key })
    }
  }

  deleteKey(key: string) {
    deleteItemFromLocalStorage(key)
    this.setState({ mouseOver: '', expanded: '' })
  }
}
