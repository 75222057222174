import { loadItemFromLocalStorage, saveObjToLocalStorage } from './LocalStorage'

export interface PrivateLabel {
  key: string
  value: string
}

export interface PrivateLabels {
  labels: PrivateLabel[]
}

class LocalStoragePrivateLabelsInternal {
  load(
    uid: string,
    projectId: string,
    formId: string,
    recordId: string
  ): PrivateLabels {
    const key = this.privateLabelsLocalStorageKey(
      uid,
      projectId,
      formId,
      recordId
    )
    const rawLabels = loadItemFromLocalStorage(key)
    if (rawLabels === null) {
      return { labels: [] }
    }
    return JSON.parse(rawLabels) as PrivateLabels
  }

  save(
    uid: string,
    projectId: string,
    formId: string,
    recordId: string,
    labels: PrivateLabels
  ) {
    const key = this.privateLabelsLocalStorageKey(
      uid,
      projectId,
      formId,
      recordId
    )
    saveObjToLocalStorage(key, labels)
  }

  private privateLabelsLocalStorageKey(
    uid: string,
    projectId: string,
    formId: string,
    recordId: string
  ): string {
    return `uid=${uid}#record-privatelabels#${projectId}#${formId}#${recordId}`
  }
}

export const LocalStoragePrivateLabels = new LocalStoragePrivateLabelsInternal()
