import React from 'react'
import { ActiveRecord } from '../../domain/caserecord/ActiveRecord'

import './RichRecordContainer.css'
import './StatusBar.css'

interface StatusBarProps {
  record: ActiveRecord
  emptyWarningsVisible: boolean
  onToggleErrors: () => void
}

export class StatusBar extends React.Component<StatusBarProps> {
  render() {
    // TODO(pawel): this method should be method on the record itself
    const [filled, total] = this.props.record.completionStatus()
    const ratio = total === 0 ? 0 : Number((filled / total) * 100).toFixed(1)
    return (
      <div className="row m-0">
        <div className="col-4">
          <button
            type="button"
            className="btn btn-secondary btn-width"
            onClick={() => this.props.onToggleErrors()}
          >
            {this.props.emptyWarningsVisible
              ? 'Brakujące pola - nie pokazuj'
              : 'Brakujące pola - pokaż'}
          </button>
        </div>
        <div className="col-8">
          {/* <span className="fill-level float-end">
            Stopień uzupełnienia: {filled} / {total} ({ratio}%)
          </span> */}
        </div>
      </div>
    )
  }

  calculateStatus() {
    let filled = 0
    let total = 0
    return [filled, total]
  }
}
