import React from 'react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../../auth/Firebase'

import './ProjectsListComponent.css'

export function ProjectListComponent() {
  const auth = useAuthState()
  let navigate = useNavigate()

  const isAuthenticated = auth.isAuthenticated
  const canTurpol =
    isAuthenticated &&
    (auth.user?.isAllowedTo('researcher:turpol/rak-piersi') ||
      auth.user?.isAllowedTo('researcher_edit_only:turpol/rak-piersi') ||
      auth.user?.isAllowedTo('auditor:turpol/rak-piersi'))
  const canUroonkologia =
    isAuthenticated &&
    (auth.user?.isAllowedTo('researcher:uroonkologia/*') ||
      auth.user?.isAllowedTo('researcher_edit_only:uroonkologia/*') ||
      auth.user?.isAllowedTo('auditor:uroonkologia/*'))
  const calUrocapol =
    isAuthenticated &&
    (auth.user?.isAllowedTo('researcher:urocapol/*') ||
      auth.user?.isAllowedTo('researcher_edit_only:urocapol/*') ||
      auth.user?.isAllowedTo('auditor:urocapol/*'))

  return (
    <div className="proj-list-container">
      <div className="proj-list-header">Lista projektów</div>
      <div>{renderTurpol(canTurpol)}</div>
      <div>{renderUroonkologia(canUroonkologia)}</div>
      <div>{renderUrocapol(calUrocapol)}</div>
    </div>
  )
}

function renderTurpol(canTurpol: boolean | Boolean | undefined) {
  if (!canTurpol || canTurpol === undefined) {
    return null
  }
  return <Link to="/projects/turpol">TURPOL</Link>
}

function renderUroonkologia(canUroonkologia: boolean | Boolean | undefined) {
  if (!canUroonkologia || canUroonkologia === undefined) {
    return null
  }
  return <Link to="/projects/uroonkologia">Uroonkologia</Link>
}

function renderUrocapol(canUrocapol: boolean | Boolean | undefined) {
  if (!canUrocapol || canUrocapol === undefined) {
    return null
  }
  return <Link to="/projects/urocapol">UROCAPOL</Link>
}
