import React from 'react'

import { PrivateLabel } from '../../utils/localstorage/PrivateLabels'

import './RecordContainer.css'
import './PrivateLabelsComponent.css'

interface PrivateLabelsComponentProps {
  labels: PrivateLabel[]
  setLabels: (updatedLabels: PrivateLabel[]) => void
}

export class PrivateLabelsComponent extends React.Component<PrivateLabelsComponentProps> {
  render(): React.ReactNode {
    return (
      <div className="privLab sec-pad-1">
        <div id="private" className="privLabHeader">
          Prywatne etykiety
        </div>
        <div className="privLabSubheader">
          Prywatne etykiety są zapisane tylko lokalnie na tym komputerze. Te
          dane nie są wysyłane na serwer
        </div>

        {this.renderCurrentLabels()}
        {this.renderAddNewLabel()}
      </div>
    )
  }

  renderCurrentLabels() {
    return (
      <div>
        {this.props.labels.map((pl: PrivateLabel, index: number) => {
          const formId = `private-label-${index}`
          return (
            <div key={index} className="row m-1">
              <label htmlFor={formId} className="col-3 col-form-label">
                {pl.key}:
              </label>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  id={formId}
                  value={pl.value}
                  readOnly={true}
                />
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-secondary label-btn"
                  onClick={() => this.removeLabel(index)}
                >
                  Usuń etykietę
                </button>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  renderAddNewLabel() {
    return (
      <div className="row m-1">
        <div className="col-3">
          <input
            type="text"
            className="form-control"
            id="pl-new-key"
            placeholder="Nazwa etykiety"
          />
        </div>
        <div className="col-7">
          <input
            type="text"
            className="form-control"
            id="pl-new-val"
            placeholder="Wartość etykiety"
          />
        </div>
        <div className="col-2">
          <button
            type="button"
            className="btn btn-primary label-btn"
            onClick={() => this.addLabel()}
          >
            Dodaj etykietę
          </button>
        </div>
      </div>
    )
  }

  removeLabel(removeAt: number) {
    const filtered = this.props.labels.filter((p, index) => {
      return index !== removeAt
    })
    this.props.setLabels(filtered)
  }

  addLabel() {
    const keyInput = document.getElementById(
      'pl-new-key'
    ) as HTMLInputElement | null
    const key = keyInput !== null ? keyInput.value : ''
    if (key === '' || key.length === 0) {
      return
    }

    const valueInput = document.getElementById(
      'pl-new-val'
    ) as HTMLInputElement | null
    const value = valueInput !== null ? valueInput.value : ''
    if (value === '' || value.length === 0) {
      return
    }

    const clone = [...this.props.labels]
    clone.push({ key: key, value: value })
    keyInput!!.value = ''
    valueInput!!.value = ''
    this.props.setLabels(clone)
  }
}
