import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import './App.css'

import { MenuContainer } from './view/menu/MenuComponent'

import { AnalysisCSVExportContainer } from './view/analysis/AnalysisCSVExportContainer'
import { AnalysisProjectsListComponent } from './view/analysis/AnalysisProjectsListComponent'
import { AuditEditRecordContainer } from './view/audit/AuditEditRecordContainer'
import { AuditProjectsListComponent } from './view/audit/AuditProjectsListComponent'
import { AuditRecordListContainer } from './view/audit/AuditRecordListContainer'
import { AuthContextDebugComponent } from './view/debug/AuthContextDebugComponent'
import { AuthContextProvider } from './auth/Firebase'
import { AuthRequired } from './view/auth/AuthRequired'
import { EditRecordContainer } from './view/record/EditRecordContainer'
import { FormsListContainer } from './view/formslist/FormsListContainer'
import { FrontpageComponent } from './view/frontpage/FrontpageComponent'
import { LocalStorageComponent } from './view/admin/localstorage/LocalStorageComponent'
import { LoginComponent } from './view/auth/LoginComponent'
import { NewRecordContainer } from './view/record/NewRecordContainer'
import { ProjectTurpolComponent } from './view/projects/ProjectTurpolComponent'
import { ProjectUroonkologiaComponent } from './view/projects/ProjectUroonkologiaComponent'
import { ProjectUrocapolComponent } from './view/projects/ProjectUrocapolComponent'
import { RecordListContainer } from './view/recordlist/RecordListContainer'
import { SettingsContainer } from './view/settings/SettingsContainer'
import { ProjectListComponent } from './view/projects/ProjectsListComponent'

Sentry.init({
  dsn: 'https://9742c2ae89d44f63b809c77d2e693e45@o4504665269796864.ingest.sentry.io/4504665371246592',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

function App() {
  return (
    <div className="app">
      <AuthContextProvider>
        <Router>
          <MenuContainer />

          <Routes>
            <Route path="/login" element={<LoginComponent />} />

            <Route index path="/" element={<FrontpageComponent />} />

            <Route
              path="/projects"
              element={
                <AuthRequired scopes={[]}>
                  <ProjectListComponent />
                </AuthRequired>
              }
            />

            <Route
              index
              path="/projects/turpol"
              element={<ProjectTurpolComponent />}
            />
            <Route
              path="/projects/uroonkologia"
              element={<ProjectUroonkologiaComponent />}
            />
            <Route
              path="/projects/urocapol"
              element={<ProjectUrocapolComponent />}
            />

            <Route
              path="/projects/:projectId/forms"
              element={
                <AuthRequired scopes={["researcher#:projectId/*", "researcher_edit_only#:projectId/*"]}>
                  <FormsListContainer />
                </AuthRequired>
              }
            />
            <Route
              path="/projects/:projectId/forms/:formId/records"
              element={
                <AuthRequired scopes={["researcher#:projectId/*", "researcher_edit_only#:projectId/*"]}>
                  <RecordListContainer />
                </AuthRequired>
              }
            />
            <Route
              path="/projects/:projectId/forms/:formId/records/new"
              element={
                <AuthRequired scopes={["researcher#:projectId/:formId"]}>
                  <NewRecordContainer />
                </AuthRequired>
              }
            />
            <Route
              path="/projects/:projectId/forms/:formId/records/:recordId"
              element={
                <AuthRequired scopes={["researcher#:projectId/*", "researcher_edit_only#:projectId/*"]}>
                  <EditRecordContainer />
                </AuthRequired>
              }
            />
            <Route
              path="/audit"
              element={
                <AuthRequired scopes={["auditor:*/*"]}>
                  <AuditProjectsListComponent />
                </AuthRequired>
              }
            />
            <Route
              path="/audit/:projectId/forms/:formId/records"
              element={
                <AuthRequired scopes={["auditor#:projectId/:formId"]}>
                  <AuditRecordListContainer />
                </AuthRequired>
              }
            />
            <Route
              path="/audit/:projectId/forms/:formId/records/:recordId"
              element={
                <AuthRequired scopes={["auditor#:projectId/:formId"]}>
                  <AuditEditRecordContainer />
                </AuthRequired>
              }
            />

            <Route
              path="/analysis"
              element={
                <AuthRequired scopes={["auditor:*/*"]}>
                  <AnalysisProjectsListComponent />
                </AuthRequired>
              }
            />
            <Route
              path="/analysis/:projectId/forms/:formId/records"
              element={
                <AuthRequired scopes={["auditor#:projectId/:formId"]}>
                  <AnalysisCSVExportContainer />
                </AuthRequired>
              }
            />
            <Route
              path="/settings"
              element={
                <AuthRequired scopes={[]}>
                  <SettingsContainer />
                </AuthRequired>
              }
            />
            <Route
              path="/debug/localstorage"
              element={
                <AuthRequired scopes={[]}>
                  <LocalStorageComponent />
                </AuthRequired>
              }
            />
            <Route
              path="/debug/logindata"
              element={
                <AuthRequired scopes={[]}>
                  <AuthContextDebugComponent />
                </AuthRequired>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </AuthContextProvider>
    </div>
  )
}

export default App
