import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useAuthState } from '../../auth/Firebase'
import { FormDescription } from '../../domain/caserecord/FormDescription'
import { Error } from '../../domain/base/Result'
import { fetchFormDescriptions } from '../../utils/fetch/FetchForms'

import './FormsListContainer.css'

export interface FormsListComponentProps {
  projectId: string
  forms: FormDescription[]
  error: Error | null
}

export class FormsListComponent extends React.Component<FormsListComponentProps> {
  render() {
    return (
      <div className="FormsListView">
        <div className="title">Lista ankiet</div>
        {this.props.forms.map((form, index) => {
          return (
            <div key={index}>
              <Link to={this.formsLink(form.id)}>{form.display_name}</Link>
            </div>
          )
        })}
        {this.renderError()}
      </div>
    )
  }

  formsLink(formId: string) {
    return `/projects/${this.props.projectId}/forms/${formId}/records`
  }

  renderError() {
    if (this.props.error === null) {
      return null
    }
    return (
      <div className="error">
        <pre>{JSON.stringify(this.props.error || {}, null, '  ')}</pre>
      </div>
    )
  }
}

export function FormsListContainer() {
  let authState = useAuthState()
  let { projectId } = useParams()
  if (projectId === undefined) {
    throw 'projectId cannot be undefined'
  }

  console.log('project-id', projectId)

  const [forms, setForms] = useState<FormDescription[]>([])
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const user = authState.user
    if (user === null) {
      return
    }
    authState.firebaseUser
      ?.getIdToken()
      .then((token) => fetchFormDescriptions(token, user, projectId!!))
      .then((response) => response.json())
      .then((response) => {
        setForms(response)
        setError(null)
      })
      .catch((error) => {
        setForms([])
        setError(error)
      })
  }, [authState.firebaseUser, authState.user, projectId])

  return (
    <FormsListComponent projectId={projectId} forms={forms} error={error} />
  )
}
