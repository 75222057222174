import React from 'react'

import {
  ActiveItem,
  ActiveRecord,
  ActiveSection,
} from '../../domain/caserecord/ActiveRecord'

import './NavigationBar.css'

export interface NavigationBarProps {
  record: ActiveRecord | null
  renderPrivate: boolean
  activeId: string
}

export class NavigationBar extends React.Component<NavigationBarProps> {
  render() {
    return <div className="nav-bar sticky-top">{this.renderNav()}</div>
  }

  renderNav() {
    if (this.props.record === null) {
      return <div></div>
    }
    return (
      <div>
        {this.renderPrivateLabelsLink()}
        {this.props.record.root.children.map((item, index) => {
          return this.renderSection(item as ActiveSection, 1)
        })}
      </div>
    )
  }

  renderPrivateLabelsLink() {
    if (!this.props.renderPrivate) {
      return null
    }
    return (
      <div className="nav-link">
        <a href="#private">Prywatne etykiety</a>
      </div>
    )
  }

  renderSection(sec: ActiveSection, depth: number) {
    if (depth > 2) {
      return null
    }

    const key = sec.id
    //const sectionTemplate = mainTemplate.getItemById(sec.templateRef) as CRSection
    const childrenSections = sec.children.filter((child) => {
      // const childTemplate = mainTemplate.getItemById(child.templateRef)
      // return childTemplate?.type === 'section'
      return child.type === 'sec'
    })

    let nameToRender = sec.title
    if (nameToRender.length > 2000) {
      nameToRender = nameToRender.substring(0, 20) + '[...]'
    }

    let activeClassName = ''
    if (sec.id === this.props.activeId) {
      activeClassName = 'nav-spy-active'
    }

    if (depth === 2) {
      activeClassName += ' depth-pad-2'
    }

    return (
      <div key={key}>
        <div className={'nav-link ' + activeClassName}>
          <a href={'#' + key}>{nameToRender}</a>
        </div>
        {childrenSections.map((item: ActiveItem, index: number) => {
          return this.renderSection(item as ActiveSection, depth + 1)
        })}
      </div>
    )
  }

  renderLink(key: string, href: string, name: string) {
    return (
      <div key={key} className="nav-link">
        <a href={href}>{name}</a>
      </div>
    )
  }
}
