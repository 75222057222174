import React from 'react'
import {
  ActiveRecord,
  ActiveItem,
  ActiveSection,
} from '../../domain/caserecord/ActiveRecord'
import { SectionComponent } from './SectionComponent'

import './RecordContainer.css'
import { RecordRenderConfig } from './RecordRenderConfig'

export type OnQuestionChanged = (itemRef: string, value: any) => void
export type OnUnitChanged = (itemRef: string, unit: any) => void

export type OnRecordAction = (actionId: string) => void

// hook for children to notify parent about the children element position
export type OnSetRef = (
  itemRef: string,
  nestingLevel: number,
  element: Element
) => void

export type OnActiveSectionChanged = (sectionId: string) => void

export interface RecordComponentProps {
  record: ActiveRecord

  showWarningsOnEmpty: boolean
  copyDateButton: boolean

  onQuestionChanged: OnQuestionChanged
  onUnitChanged: OnUnitChanged
  onRecordAction: OnRecordAction

  onActiveSectionChanged: OnActiveSectionChanged | null
}

export interface RecordComponentState {
  yPositions: Map<string, number>
  activeHeader: string

  cachedDate: Date | null
}

export class RecordComponent extends React.Component<
  RecordComponentProps,
  RecordComponentState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      yPositions: new Map<string, number>(),
      activeHeader: '',

      cachedDate: null,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this), {
      passive: true,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
  }

  /**
   * scroll handling is implemented to trigger "the active section has changed" event,
   * that can be used to update the navigation bar (scroll spy).
   *
   * The event is calculated based on the current Y-scroll position (thank to the onScroll event
   * installed on component mount). The section that's top is closed to the Y-scroll position
   * (top of the screen) wins.
   *
   * Tricks:
   * - knowing the section positions requires registering their refs (using the onSetRef callback
   *   that is passed to each section)
   * - actual calculation of the "closest to the top" section is more art than science.
   *   For now we use some hardcoded values for margins that makes the active section switch
   *   a bit nicer to look
   * @param event
   * @returns
   */
  handleScroll(event: any) {
    if (this.props.onActiveSectionChanged === null) {
      return
    }

    const yOffset = window.pageYOffset

    const arbitraryWeAreOnTheTopOfThePageYPosition = 200
    // 200 is an arbitrary number
    if (yOffset < arbitraryWeAreOnTheTopOfThePageYPosition) {
      if (this.state.activeHeader !== '') {
        this.setState({
          activeHeader: '',
        })
        this.props.onActiveSectionChanged('')
      }
      return
    }

    let bestDist = window.outerHeight
    let bestId = ''
    this.state.yPositions.forEach((value: number, key: string) => {
      const dist = yOffset - value
      if (dist >= -60 && Math.abs(dist) < bestDist) {
        bestDist = Math.abs(dist)
        bestId = key
      }
    })

    if (this.state.activeHeader !== bestId) {
      // console.log('setting best id', bestDist, bestId)
      this.setState({
        activeHeader: bestId,
      })
      this.props.onActiveSectionChanged(bestId)
    }
  }

  render() {
    const onSetRef = (
      sectionId: string,
      nestingLevel: number,
      element: Element
    ) => {
      if (
        element !== null &&
        this.state.yPositions.get(sectionId) === undefined &&
        nestingLevel <= 2
      ) {
        const top = element.getBoundingClientRect().top
        this.state.yPositions.set(sectionId, top)
      }
    }

    // console.log(this.props.template)
    // console.log(this.props.record)
    return (
      <div>
        {this.props.record.root.children.map(
          (resp: ActiveItem, rIndex: number) => {
            // NOTE(pawel): at the first level of hierarchy it's OK to do some casting

            return (
              <SectionComponent
                key={rIndex}
                resp={resp as ActiveSection}
                renderConfig={{
                  showWarningsOnEmpty: this.props.showWarningsOnEmpty,
                  copyDateButton: this.props.copyDateButton,
                  cachedDate: this.state.cachedDate,
                  setCachedDate: (value: Date) => {
                    this.setState({cachedDate: value})
                  },
                }}
                nestingLevel={1}
                onQuestionChanged={this.props.onQuestionChanged}
                onUnitChanged={this.props.onUnitChanged}
                actions={this.props.record.actions}
                onRecordAction={this.props.onRecordAction}
                onSetRef={onSetRef}
              />
            )
          }
        )}
      </div>
    )
  }
}

export type onRecordUpdated = (record: ActiveRecord) => void

export interface RecordContainerProps {
  record: ActiveRecord
  showWarningsOnEmpty: boolean
  copyDateButton: boolean
  onQuestionChanged: OnQuestionChanged
  onUnitChanged: OnUnitChanged
  onRecordAction: OnRecordAction

  onActiveSectionChanged: OnActiveSectionChanged | null
}

export class RecordContainer extends React.Component<RecordContainerProps> {
  render() {
    return (
      <RecordComponent
        record={this.props.record}
        showWarningsOnEmpty={this.props.showWarningsOnEmpty}
        copyDateButton={this.props.copyDateButton}
        onQuestionChanged={this.props.onQuestionChanged}
        onUnitChanged={this.props.onUnitChanged}
        onRecordAction={this.props.onRecordAction}
        onActiveSectionChanged={this.props.onActiveSectionChanged}
      />
    )
  }
}
