export function saveObjToLocalStorage(key: string, obj: any) {
  window.localStorage.setItem(key, JSON.stringify(obj))
}

export function loadItemFromLocalStorage(key: string): any {
  return window.localStorage.getItem(key)
}

export function deleteItemFromLocalStorage(key: string) {
  window.localStorage.removeItem(key)
}

export function listLocalStorageKeys(): string[] {
  const n = window.localStorage.length
  let keys = Array<string>(n)
  for (let i = 0; i < n; i++) {
    keys[i] = window.localStorage.key(i)!!
  }
  return keys
}

/*
function dumpLocalStorage() {
  let d = {}
  const n = window.localStorage.length
  for (let i = 0; i < n; i++) {
    let key = window.localStorage.key(i)
    if (key.includes("private")) {
      let value = window.localStorage.getItem(key)
      d[key]  = value
    }
  }  
  console.log(JSON.stringify(d, null, 2))
}
*/
