import { inspect } from 'util'

import { Versioned } from '../base/Versioned'
import * as result from '../base/Result'
import { TreeNode, visit, InvalidNodeError } from '../base/tree/TreeOperator'
import { EmptyQuestionEffects, QuestionEffects } from './EffectsDef'

export interface CRTemplateDescription {
  readonly id: string
  readonly version: string
  readonly display_name: string
}

export class CRTemplate implements Versioned, CRTemplateDescription {
  readonly id: string
  readonly version: string
  readonly display_name: string
  readonly dataRenders: CRSectionOrSpecial[]
  readonly dataRendersIndex: Map<string, CRChild>

  constructor(
    id: string,
    version: string,
    display_name: string,
    dataRenders: CRSectionOrSpecial[],
  ) {
    this.id = id
    this.version = version
    this.display_name = display_name
    this.dataRenders = dataRenders
    this.dataRendersIndex = CRTemplate.buildItemsIndex(dataRenders)
  }

  private static buildItemsIndex(
    dataRender: CRSectionOrSpecial[]
  ): Map<string, CRChild> {
    const index = new Map<string, CRChild>()
    const onNodeVisit = function (
      node: TreeNode
    ): result.Result<string, InvalidNodeError> {
      const id = node['id'] as string
      if (id === undefined) {
        return new InvalidNodeError(`missing node id: ${inspect(node)}`)
      }
      if (index.has(id)) {
        return new InvalidNodeError(`duplicate node id: ${id}`)
      }
      index.set(id, node as CRChild)

      return result.success('')
    }

    for (const child of dataRender) {
      const visitRes = visit(child, onNodeVisit)
      if (result.isErr(visitRes)) {
        throw visitRes
      }
    }

    return index
  }

  static fromJSON(jsonData: string): result.Result<CRTemplate, ParsingError> {
    const loaded = JSON.parse(jsonData)
    console.log(inspect(loaded, true, 20, true))
    return this.fromObject(loaded)
  }

  static fromObject(obj: any): result.Result<CRTemplate, ParsingError> {
    const idRes = checkNonEmptyString(obj, 'id')
    if (result.isErr(idRes)) {
      return idRes
    }

    const versionRes = checkNonEmptyString(obj, 'version')
    if (result.isErr(versionRes)) {
      return versionRes
    }

    const displayName = checkNonEmptyString(obj, 'displayName')
    if (result.isErr(displayName)) {
      return displayName
    }

    const rawDataRenders = obj['dataRenders'] as any[]
    const dataRenders: CRSectionOrSpecial[] = []
    for (let i = 0; i < rawDataRenders.length; i++) {
      const objType = rawDataRenders[i].type
      if (objType === 'sec') {
        const sectionRes = CRSection.fromObject(rawDataRenders[i])
        if (result.isErr(sectionRes)) {
          return new ParsingError(`section ${i + 1}: ${sectionRes.message}`)
        }
        dataRenders.push(sectionRes.value)
        continue
      }
      // if (objType === 'special') {
      //   const specialRes = SpecialTemplate.fromObject(rawDataRenders[i])
      //   if (result.isErr(specialRes)) {
      //     return new ParsingError(`special ${i + 1}: ${specialRes.message}`)
      //   }
      //   dataRenders.push(specialRes.value)
      //   continue
      // }
      return new ParsingError(
        `unknown type ${i + 1}: ${objType} ${Object.keys(rawDataRenders[i])}`
      )
    }

    const crt = new CRTemplate(
      idRes.value,
      versionRes.value,
      displayName.value,
      dataRenders,

    )
    return result.success(crt)
  }

  getVersion(): string {
    return this.version
  }

  getItemById(itemId: string): CRChild | null {
    const item = this.dataRendersIndex.get(itemId)
    return item !== undefined ? item : null
  }

  // getTemplateFor(pos: number[]): CRItem | null {
  //   if (pos === undefined || pos.length === 0) {
  //     return null
  //   }
  //   if (pos.length === 1) {
  //     return this.items[pos[0]]
  //   }
  //   return this.items[pos[0]].getTemplateFor(pos.slice(1, pos.length))
  // }
}

export type CRChild = CRSection | QuestionTemplate

export type CRSectionOrSpecial = CRSection

export class CRSection {
  readonly type = 'section'
  readonly id: string
  readonly title: string
  readonly initialRender: boolean
  readonly children: CRChild[]

  constructor(
    id: string,
    title: string,
    initialRender: boolean,
    children: CRChild[]
  ) {
    this.id = id
    this.title = title
    this.initialRender = initialRender
    this.children = children
  }

  static fromObject(obj: any): result.Result<CRSection, ParsingError> {
    const idRes = checkNonEmptyString(obj, 'id')
    if (result.isErr(idRes)) {
      return idRes
    }

    const titleRes = checkNonEmptyString(obj, 'title')
    if (result.isErr(titleRes)) {
      return titleRes
    }

    let initialRender = true
    if (obj['initialRender'] === false) {
      initialRender = false
    }

    const rawItems = obj['children'] as any[]
    if (rawItems === null) {
      return new ParsingError('missing required "items"')
    }

    const items: CRChild[] = []
    for (let i = 0; i < rawItems.length; i++) {
      let itemType = ''
      const rawType = rawItems[i]['type']
      if (rawType === 'sec' || rawType === 'section') {
        itemType = 'section'
      } else if (
        rawType === 'question' ||
        rawType === 'q' ||
        (rawType === undefined &&
          rawItems[i]['question'] !== undefined &&
          rawItems[i]['question'] !== '')
      ) {
        itemType = 'question'
      }

      let parseRes: result.Result<CRChild, ParsingError>
      switch (itemType) {
        case 'section':
          parseRes = CRSection.fromObject(rawItems[i])
          break
        case 'question':
          parseRes = QuestionTemplate.fromObject(rawItems[i])
          break
        default:
          parseRes = new ParsingError(`invalid item type: ${itemType}`)
      }

      if (result.isErr(parseRes)) {
        return new ParsingError(
          `${titleRes.value} item ${i + 1}: ${parseRes.message}`
        )
      }
      items.push(parseRes.value)
    }

    const cr = new CRSection(idRes.value, titleRes.value, initialRender, items)
    return result.success(cr)
  }
}

export enum ResponseType {
  invalidDoNotUse,
  ShortText,
  FreeText,
  Number,
  Date,
}

export class QuestionTemplate {
  readonly type = 'question'

  readonly id: string

  readonly question: string

  readonly comment: string | null

  readonly responseType: ResponseType

  // multi response
  readonly multiple: boolean

  /**
   * responseOptions tells what options are valid options for the question.
   *
   * Returns null if there are no options
   */
  readonly responseOptions: any[] | null = null

  readonly metadata: string | null = null

  readonly effects: QuestionEffects

  constructor(
    id: string,
    question: string,
    comment: string | null,
    responseType: ResponseType,
    multiple: boolean,
    responseOptions: any[] | null,
    metadata: string | null,
    effects: QuestionEffects
  ) {
    this.id = id
    this.question = question
    this.comment = comment
    this.responseType = responseType
    this.multiple = multiple
    this.responseOptions = responseOptions
    this.metadata = metadata
    this.effects = effects
  }

  static fromObject(obj: any): result.Result<QuestionTemplate, ParsingError> {
    const idRes = checkNonEmptyString(obj, 'id')
    if (result.isErr(idRes)) {
      return idRes
    }

    const questionRes = checkNonEmptyString(obj, 'question')
    if (result.isErr(questionRes)) {
      return questionRes
    }

    const comment = obj['comment'] ?? null

    const responeType = checkNonEmptyString(obj, 'responseType')
    if (result.isErr(responeType)) {
      return responeType
    }
    let responseEnum = ResponseType.ShortText
    let options = obj['options']
    if (responeType.value === 'ShortText') {
      responseEnum = ResponseType.ShortText
    } else if (responeType.value === 'FreeText') {
      responseEnum = ResponseType.FreeText
    } else if (responeType.value === 'Date') {
      responseEnum = ResponseType.Date
    } else if (responeType.value === 'Number') {
      responseEnum = ResponseType.Number
    } else if (responeType.value === 'YesNo') {
      responseEnum = ResponseType.ShortText
      options = ['TAK', 'NIE']
    } else {
      return new ParsingError(
        `q: ${questionRes.value}, unrecognized respone type: ${responeType.value}`
      )
    }

    const rawMultiple = obj['multiple']
    let multiple = false
    if (rawMultiple !== undefined && rawMultiple === true) {
      multiple = true
    }

    const metadata = obj['metadata']

    let effects: QuestionEffects =
      (obj['effects'] as QuestionEffects) ?? EmptyQuestionEffects

    const qt = new QuestionTemplate(
      idRes.value,
      questionRes.value,
      comment,
      responseEnum,
      multiple,
      options !== undefined ? options : null,
      metadata !== undefined ? metadata : null,
      effects
    )
    return result.success(qt)
  }

  // TODO(pawel): validation error
  validate(resp: any): ValidationError | null {
    return null
  }
}

export class ValidationError implements result.Error {
  code = 'VALIDATION_ERROR'
  message: string = ''
  retriable: boolean = false

  constructor(message: string = '') {
    this.message = message
  }
}

export class ParsingError implements result.Error {
  code = 'PARSING_ERROR'
  message: string = ''
  retriable: boolean = false

  constructor(message: string = '') {
    this.message = message
  }
}

function checkNonEmptyString(
  obj: any,
  key: string
): result.Result<string, ParsingError> {
  const value = obj[key]
  if (value === undefined || typeof value !== 'string' || value === '') {
    return new ParsingError(`missing or empty property: ${key}`)
  }
  return result.success(value as string)
}
