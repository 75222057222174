import { getAuth } from '@firebase/auth'
import { EmailAuthProvider } from '@firebase/auth'

import FirebaseAuth from './FirebaseUI'

import './LoginComponent.css'

export function LoginComponent(props: any) {
  const uiConfig = {
    signInSuccessUrl: '/',
    signInOptions: [EmailAuthProvider.PROVIDER_ID],
    // Terms of service url.
    tosUrl: '<your-tos-url>',
  }

  return (
    <div className="login">
      <FirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
    </div>
  )
}
