import React, { useEffect, useState } from 'react'

import {
  fetchRecords,
  RecordListItem,
  recordListItemFromDict,
} from '../../utils/fetch/FetchRecords'
import { useAuthState } from '../../auth/Firebase'
import { InvalidStateError } from '../../domain/base/StandardErrors'
import { useParams } from 'react-router-dom'

import '../BaseComponent.css'
import './Watermark.css'
import './AuditRecordListContainer.css'
import { Columns, RecordListTable } from '../recordlist/RecordListTable'
import { PageLoadingComponent } from '../projects/PageLoadingComponent'
import { fetchFormDescription } from '../../utils/fetch/FetchForms'
import { FormDescription } from '../../domain/caserecord/FormDescription'

interface ActiveRecordSnippetWithResearcherName extends RecordListItem {
  researcher_name: string
}

interface AuditRecordListComponentProps {
  projectId: string
  formId: string
  formDescription: FormDescription
  snippets: ActiveRecordSnippetWithResearcherName[]
}

export class AuditRecordListComponent extends React.Component<AuditRecordListComponentProps> {
  render() {
    const snippets: ActiveRecordSnippetWithResearcherName[] =
      this.props.snippets
    snippets.sort((a, b) => {
      // descending, latest first
      return b.version - a.version
    })

    return (
      <div className="component-view watermarked-audit">
        <div>
          <span className="component-view-header">
            Ankieta: {this.props.formDescription.display_name}
          </span>
          <span className="component-view-header-fineprint">
            wersja: {this.props.formDescription.version}
          </span>
        </div>

        <div className="crlList">
          <div className="crlSection">
            <RecordListTable
              columns={[
                Columns.Order,
                Columns.ResearcherName,
                Columns.AuditRecordIdLink(
                  this.props.projectId,
                  this.props.formId
                ),
                Columns.Updated,
                Columns.UpdatedBy,
                //Columns.PrivateLabels,
              ]}
              data={snippets}
            />
          </div>
        </div>
      </div>
    )
  }
}

export function AuditRecordListContainer(props: any) {
  let authState = useAuthState()
  let { projectId, formId } = useParams()
  const [formDescription, setFormDescription] =
    useState<FormDescription | null>(null)
  const [records, setRecords] = useState<RecordListItem[]>([])
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const user = authState.user
    if (
      user === null ||
      projectId === null ||
      projectId === undefined ||
      formId === null ||
      formId === undefined
    ) {
      return
    }

    authState.firebaseUser
      ?.getIdToken()
      .then((token) =>
        fetchFormDescription(token, user!!, projectId!!, formId!!)
      )
      .then((response) => response.json())
      .then((response) => {
        setFormDescription(response as FormDescription)
      })
      .catch((error) => {
        setRecords([])
        setError(error)
      })
  }, [authState.firebaseUser, authState.user, projectId, formId])

  useEffect(() => {
    const user = authState.user
    if (
      user === null ||
      projectId === null ||
      projectId === undefined ||
      formId === null ||
      formId === undefined
    ) {
      return
    }
    authState.firebaseUser
      ?.getIdToken()
      .then((token) => fetchRecords(token, projectId!!, formId!!, false))
      .then((response) => response.json())
      .then((response) => {
        const items = response.records.map((it: any) =>
          recordListItemFromDict(it)
        )

        setRecords(items)
        setError(null)
      })
      .catch((error) => {
        setRecords([])
        setError(error)
      })
  }, [authState.firebaseUser, authState.user, projectId, formId])

  if (projectId === null || projectId === undefined) {
    const error = new InvalidStateError('projectId is empty')
    return <div>{error.message}</div>
  }
  if (formId === null || formId === undefined) {
    const error = new InvalidStateError('templateVersion is empty')
    return <div>{error.message}</div>
  }
  if (formDescription === null) {
    return <PageLoadingComponent />
  }

  return (
    <AuditRecordListComponent
      projectId={projectId}
      formId={formId}
      formDescription={formDescription}
      snippets={records || []}
    />
  )
}
