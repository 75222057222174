import {
  ActionDef,
  PositionDirAfter,
  PositionDirRightFrom,
} from '../../domain/caserecord/ActiveRecord'
import { OnRecordAction } from './RecordContainer'

import './RecordContainer.css'

const BUTTON_WIDTH_COL_3_THRESHOLD = 30

export interface ActionButtonComponentProps {
  actionGroups: ActionDef[][]
  onRecordAction: OnRecordAction
}

export function ActionButtonsComponent(props: ActionButtonComponentProps) {
  return (
    <div>
      {props.actionGroups.map((group: ActionDef[], index: number) => {
        let wideGroup = false
        for (let i = 0; i < group.length; i++) {
          if (group[i].button_text.length >= BUTTON_WIDTH_COL_3_THRESHOLD) {
            wideGroup = true
          }
        }
        return (
          <div key={index} className="row m-1 actions-group">
            {group.map((def: ActionDef, index: number) => {
              return renderAfterButton(
                def,
                wideGroup,
                index,
                props.onRecordAction
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export function renderRightFromButton(
  actionDef: ActionDef,
  index: number,
  onRecordAction: OnRecordAction
) {
  const onClick = () => {
    onRecordAction(actionDef.id)
  }

  return (
    <div className="col-1" key={index}>
      <button onClick={onClick} className={_calculateBtnClass(actionDef)}>
        {actionDef.button_text}
      </button>
    </div>
  )
}

export function renderAfterButton(
  actionDef: ActionDef,
  wide: boolean,
  index: number,
  onRecordAction: OnRecordAction
) {
  const onClick = () => {
    onRecordAction(actionDef.id)
  }

  let wrapperDivClass = 'col-2'
  if (wide) {
    wrapperDivClass = 'col-3'
  }

  return (
    <div className={wrapperDivClass} key={index}>
      <button onClick={onClick} className={_calculateBtnClass(actionDef)}>
        {actionDef.button_text}
      </button>
    </div>
  )
}

function _calculateBtnClass(def: ActionDef) {
  let cls = 'btn btn-primary float-none add-section-btn '
  cls += _mapButtonTypeToExtraClass(def.button_type)
  if (def.position_dir === PositionDirRightFrom) {
    cls += ' w100'
  }
  return cls
}

function _mapButtonTypeToExtraClass(buttonType: string) {
  if (buttonType === 'add-item') {
    return 'btn-primary'
  }
  if (buttonType === 'remove-item') {
    return 'btn-danger'
  }
  return ''
}

export interface ButtonsByPposition {
  right_from: ActionDef[]
  after: ActionDef[][]
}

export function groupButtonsByPositionDirection(actionDefs: ActionDef[]) {
  let right_from: ActionDef[] = []
  let after: ActionDef[][] = []

  for (let i = 0; i < actionDefs.length; i++) {
    const item: ActionDef = actionDefs[i]
    const position_dir = item.position_dir || PositionDirAfter
    const itemGroup = item.button_group || 0

    if (position_dir === PositionDirAfter) {
      while (after.length <= itemGroup) {
        after.push([])
      }
      after[itemGroup].push(item)
    } else {
      right_from.push(item)
    }
  }

  right_from.sort((a: ActionDef, b: ActionDef) => {
    return a.button_group!! - b.button_group!!
  })

  return {
    right_from: right_from,
    after: after,
  }
}
