import React, { useEffect, useState } from 'react'

import {
  fetchRecords,
  RecordListItem,
  recordListItemFromDict,
} from '../../utils/fetch/FetchRecords'
import { useAuthState } from '../../auth/Firebase'
import { InvalidStateError } from '../../domain/base/StandardErrors'
import { Link, useParams } from 'react-router-dom'

import '../BaseComponent.css'
import './AnalysisCSVExportContainer.css'
import { PageLoadingComponent } from '../projects/PageLoadingComponent'
import { fetchFormDescription } from '../../utils/fetch/FetchForms'
import { FormDescription } from '../../domain/caserecord/FormDescription'
import { Columns, RecordListTable } from '../recordlist/RecordListTable'
import { CellProps } from 'react-table'
import { fetchCSVExport } from '../../utils/fetch/FetchAnalysis'

interface RecordCSVExportRow {
  researcher_name: string
  researcher_id: string
  records_size: number
}

interface AnalysisCSVExportComponentProps {
  projectId: string
  formId: string
  formDescription: FormDescription
  exportRows: RecordCSVExportRow[]

  triggerExport: (researchers: Map<string, boolean>) => void
}

interface AnalysisCSVExportComponentState {
  researcherIds: Map<string, boolean>
}

export class AnalysisCSVExportComponent extends React.Component<
  AnalysisCSVExportComponentProps,
  AnalysisCSVExportComponentState
> {
  constructor(props: AnalysisCSVExportComponentProps) {
    super(props)

    this.state = {
      researcherIds: new Map<string, boolean>(),
    }
  }

  render() {
    return (
      <div className="component-view">
        <div>
          <span className="component-view-header">
            Eksport danych dla ankiety:{' '}
            {this.props.formDescription.display_name}
          </span>
          <span className="component-view-header-fineprint">
            wersja: {this.props.formDescription.version}
          </span>
        </div>

        <div className="crlList">
          <div className="crlSection">
            <RecordListTable
              columns={[
                Columns.Order,
                Columns.ResearcherName,
                AnalysisColumns.NumberOfRecordsCol,
                AnalysisColumns.ToggleResearcherCheckbox(
                  this.state.researcherIds,
                  this.toggleResearcherForExport.bind(this)
                ),
              ]}
              data={this.props.exportRows}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 offset-md-10 buttonContainer">
            <div>
              Wybrano badaczy: {this.state.researcherIds.size} /{' '}
              {this.props.exportRows.length}
            </div>
            <button 
              disabled={this.state.researcherIds.size === 0}
              onClick={() => {this.props.triggerExport(this.state.researcherIds)}} className="btn btn-primary">
              Eksport danych
            </button>
          </div>
        </div>
      </div>
    )
  }

  toggleResearcherForExport(researcherId: string) {
    const researcher_ids = this.state.researcherIds
    if (researcher_ids.has(researcherId)) {
      researcher_ids.delete(researcherId)
    } else {
      researcher_ids.set(researcherId, true)
    }
    this.setState({ researcherIds: researcher_ids })
    console.log(this.state.researcherIds)
  }
}

export const AnalysisColumns = {
  NumberOfRecordsCol: {
    Header: 'Liczba rekordów',
    accessor: 'records_size',
    Cell: (props: CellProps<any, string>) => {
      const recordsCount = props.cell.value
      return <>{recordsCount}</>
    },
  },

  ToggleResearcherCheckbox: (
    researcherIds: Map<string, boolean>,
    toggleResearcherCb: (researcherId: string) => void
  ) => {
    return {
      Header: 'Do eksportu?',
      accessor: 'researcher_id',
      Cell: (props: CellProps<any, string>) => {
        const researcherId = props.cell.value
        const onClick = () => {
          toggleResearcherCb(researcherId)
        }
        return (
          <input
            className="form-check-input"
            type="checkbox"
            checked={researcherIds.has(researcherId)}
            id={researcherId}
            onChange={onClick}
          />
        )
      },
    }
  },
}

export function AnalysisCSVExportContainer(props: any) {
  let authState = useAuthState()
  let { projectId, formId } = useParams()
  const [formDescription, setFormDescription] =
    useState<FormDescription | null>(null)
  const [records, setRecords] = useState<RecordListItem[]>([])
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const user = authState.user
    if (
      user === null ||
      projectId === null ||
      projectId === undefined ||
      formId === null ||
      formId === undefined
    ) {
      return
    }

    authState.firebaseUser
      ?.getIdToken()
      .then((token) =>
        fetchFormDescription(token, user!!, projectId!!, formId!!)
      )
      .then((response) => response.json())
      .then((response) => {
        setFormDescription(response as FormDescription)
      })
      .catch((error) => {
        setRecords([])
        setError(error)
      })
  }, [authState.firebaseUser, authState.user, projectId, formId])

  useEffect(() => {
    const user = authState.user
    if (
      user === null ||
      projectId === null ||
      projectId === undefined ||
      formId === null ||
      formId === undefined
    ) {
      return
    }
    authState.firebaseUser
      ?.getIdToken()
      .then((token) => fetchRecords(token, projectId!!, formId!!, false))
      .then((response) => response.json())
      .then((response) => {
        const items = response.records.map((it: any) =>
          recordListItemFromDict(it)
        )

        setRecords(items)
        setError(null)
      })
      .catch((error) => {
        setRecords([])
        setError(error)
      })
  }, [authState.firebaseUser, authState.user, projectId, formId])

  if (projectId === null || projectId === undefined) {
    const error = new InvalidStateError('projectId is empty')
    return <div>{error.message}</div>
  }
  if (formId === null || formId === undefined) {
    const error = new InvalidStateError('templateVersion is empty')
    return <div>{error.message}</div>
  }
  if (formDescription === null) {
    return <PageLoadingComponent />
  }

  let records_by_researcher = new Map<string, RecordCSVExportRow>()
  for (let i = 0; i < records.length; i++) {
    const record = records[i]
    if (records_by_researcher.has(record.researcher_id)) {
      records_by_researcher.get(record.researcher_id)!!.records_size++
    } else {
      records_by_researcher.set(record.researcher_id, {
        researcher_id: record.researcher_id,
        researcher_name: record.researcher_name,
        records_size: 1,
      })
    }
  }
  const exportRows = Array.from(records_by_researcher.values()).sort((a, b) =>
    a.researcher_name.localeCompare(b.researcher_name)
  )

  const triggerExport = (researchers: Map<string, boolean>) => {
    const researcherIds = Array.from(researchers.keys())
    console.log('ui reserachers', researcherIds)

    authState.firebaseUser
      ?.getIdToken()
      .then((token) =>
        fetchCSVExport(token, projectId!!, formId!!, researcherIds)
      )
      .then((response) => {
        if (response.status >= 400) {
          window.alert(`Błąd eksportu danych ${response.statusText}`)
        } else {
          return {
            filename: fnGetFileNameFromContentDispostionHeader(
              response.headers.get('content-disposition') || ''
            ),
            blob: response.blob(),
          }
        }
      })
      .then((resObj) => {
        resObj?.blob.then((blob) => {
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([blob], { type: 'text/csv' })

          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          // https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
          if (false) {
            // window.navigator && window.navigator.msSaveOrOpenBlob) {
            // window.navigator.msSaveOrOpenBlob(newBlob)
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob)

            let link = document.createElement('a')
            link.href = objUrl
            link.download = resObj!!.filename
            link.click()

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl)
            }, 250)
          }
        })
      })
      .catch((error) => {
        window.alert(`Błąd eksportu danych ${error}`)
        setRecords([])
        setError(error)
      })
  }

  return (
    <AnalysisCSVExportComponent
      projectId={projectId}
      formId={formId}
      formDescription={formDescription}
      exportRows={exportRows}
      triggerExport={triggerExport}
    />
  )
}

// https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
let fnGetFileNameFromContentDispostionHeader = function (
  contentDisposition: string
): string {
  console.log('parsing content-dispisioton header', contentDisposition)
  const standardPattern = /filename=(["']?)(.+)\1/i
  const wrongPattern = /filename=([^"'][^;"'\n]+)/i

  if (standardPattern.test(contentDisposition)) {
    return contentDisposition.match(standardPattern)!![2]
  }

  if (wrongPattern.test(contentDisposition)) {
    return contentDisposition.match(wrongPattern)!![1]
  }
  return 'download.csv'
}
