import { serverAddr } from './ServerAddr'

export function fetchCSVExport(
  authToken: string,
  projectId: string,
  formId: string,
  researcherIds: string[]
): Promise<Response> {
  let url = `${serverAddr}/api/v1/analysis/${projectId}/forms/${formId}/records/export`
  console.log(url)
  console.log(researcherIds)
  // console.log(url)
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      researcher_ids: researcherIds,
    }),
  })
}
