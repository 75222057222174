import React from 'react'
import { AuthContext, AuthContextType } from '../../auth/Firebase'
import { fetchUserWithRoles } from '../../utils/fetch/FetchUsers'

export class AuthContextDebugComponent extends React.Component {
  static contextType: React.Context<AuthContextType> = AuthContext

  componentDidUpdate(prevProps: any) {
    if (this.context != null) {
      const authContext = this.context as AuthContextType
      if (authContext.firebaseUser != null) {
        authContext.firebaseUser.getIdToken().then((token: string) => {
          fetchUserWithRoles(token)
            .then((response) => response.json())
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.log(error)
            })
        })
      }
    }
  }

  render() {
    return <pre>{JSON.stringify(this.context, null, '  ')}</pre>
  }
}
