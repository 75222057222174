import { User } from '../../auth/User'
import { FormDescription } from '../../domain/caserecord/FormDescription'

interface FormDescriptionWithScopes {
  form: FormDescription
  scopes: string[]
}

export const TurpolForms: FormDescriptionWithScopes[] = [
  {
    form: {
      id: 'rak-piersi',
      version: '2022.10.25',
      display_name: 'Rak piersi',
    },
    scopes: [
      'researcher:turpol/rak-piersi', 
      'researcher_edit_only:turpol/rak-piersi',
      'auditor:turpol/rak-piersi'
    ],
  },
]

export const UroonkologiaForms = [
  // {
  //   form: {
  //     id: 'rak-jadra',
  //     version: '2022.07.02.a',
  //     display_name: 'Rak jądra',
  //   },
  //   scopes: ['researcher:uroonkologia/rak-jadra', 'auditor:uroonkologia/rak-jadra'],
  // },
  {
    form: {
      id: 'rak-gruczolu-krokowego',
      version: '2023.11.09',
      display_name: 'Rak gruczołu krokowego',
    },
    scopes: [
      'researcher:uroonkologia/rak-gruczolu-krokowego',
      'researcher_edit_only:uroonkologia/rak-gruczolu-krokowego',
      'auditor:uroonkologia/rak-gruczolu-krokowego',
    ],
  },
  // {
  //   form: {
  //     id: 'rak-gruczolu-krokowego-poprawki',
  //     version: '2023.11.09',
  //     display_name: 'Rak gruczołu krokowego - poprawki',
  //   },
  //   scopes: [
  //     'researcher:uroonkologia/rak-gruczolu-krokowego-poprawki',
  //     'auditor:uroonkologia/rak-gruczolu-krokowego-poprawki',
  //   ],
  // },
  // {
  //   form: {
  //     id: 'rak-pecherza',
  //     version: '2020.07.04.a',
  //     display_name: 'Rak pęcherza (w trakcie budowy)',
  //   },
  //   scopes: ['researcher:uroonkologia/rak-jadra', 'auditor:uroonkologia/rak-pecherza'],
  // },
]

export const UrocapolForms: FormDescriptionWithScopes[] = [
  {
    form: {
      id: 'rak-pecherza',
      version: '2024.08.27',
      display_name: 'Rak pęcherza',
    },
    scopes: [
      'researcher:urocapol/rak-pecherza', 
      'researcher_edit_only:urocapol/rak-pecherza',
      'auditor:urocapol/rak-pecherza'
    ],
  },
]


export const ProjectForms = new Map<string, FormDescriptionWithScopes[]>([
  ['turpol', TurpolForms],
  ['uroonkologia', UroonkologiaForms],
  ['urocapol', UrocapolForms],
])

class FakeResponse {
  value: any

  constructor(value: any) {
    this.value = value
  }

  json(): Promise<any> {
    return Promise.resolve(this.value)
  }
}

export function fetchFormDescriptions(
  authToken: string,
  user: User,
  projectId: string
): Promise<Response> {
  const projectForms = ProjectForms.get(projectId) || []
  const allowedForms = projectForms.filter((templateWithScope) => {
    return user.isAllowedForAny(templateWithScope.scopes)
  })
  //console.log(allowedTemplates)

  const descriptions = allowedForms.map(
    (templateWithScope) => templateWithScope.form
  )

  const fakeResp = new FakeResponse(descriptions)
  return Promise.resolve(fakeResp as any)
}

export function fetchFormDescription(
  authToken: string,
  user: User,
  projectId: string,
  formId: string
) {
  const projectForms = ProjectForms.get(projectId) || []
  const allowedForms = projectForms.filter((templateWithScope) => {
    return user.isAllowedForAny(templateWithScope.scopes)
  })
  const matchingForms = allowedForms.filter((templateWithScope) => {
    return templateWithScope.form.id === formId
  })

  if (matchingForms.length === 0) {
    return Promise.reject('No such form')
  } else if (matchingForms.length === 1) {
    const fakeResp = new FakeResponse(matchingForms[0].form)
    return Promise.resolve(fakeResp)
  } else {
    return Promise.reject('Internal error: too many forms')
  }
}
