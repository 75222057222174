import React from 'react'

import {
  ActionDef,
  ActiveItem,
  ActiveQuestion,
  ActiveSection,
} from '../../domain/caserecord/ActiveRecord'
import {
  ActionButtonsComponent,
  groupButtonsByPositionDirection,
  renderRightFromButton,
} from './ActionButtonsComponent'
import { QuestionComponent } from './QuestionComponent'
import {
  OnQuestionChanged,
  OnRecordAction,
  OnSetRef,
  OnUnitChanged,
} from './RecordContainer'
import { RecordRenderConfig } from './RecordRenderConfig'

import './RecordContainer.css'

export interface SectionComponentProps {
  resp: ActiveSection
  renderConfig: RecordRenderConfig
  nestingLevel: number
  onQuestionChanged: OnQuestionChanged
  onUnitChanged: OnUnitChanged
  actions: Map<string, ActionDef[]>
  onRecordAction: OnRecordAction

  onSetRef: OnSetRef | null
}

export interface SectionComponentState {
  collapsed: boolean
}

export class SectionComponent extends React.Component<
  SectionComponentProps,
  SectionComponentState
> {
  constructor(props: SectionComponentProps) {
    super(props)
    this.state = {
      collapsed: props.resp.collapsed,
    }
  }

  render() {
    const resp = this.props.resp
    const nestingLevel = this.props.nestingLevel

    const setSectionRef = (element: any) => {
      if (this.props.onSetRef !== null) {
        this.props.onSetRef(
          this.props.resp.id,
          this.props.nestingLevel,
          element
        )
      }
    }

    let actionGroups = groupButtonsByPositionDirection(
      this.props.actions.get(resp.id) || []
    )

    return (
      <div>
        <div
          className={
            'sectionContainer ' + this.calculateContainerClass(nestingLevel)
          }
          ref={setSectionRef}
        >
          {this.renderSectionTitle(resp.title, nestingLevel)}
          {!this.state.collapsed &&
            resp.children.map((child: ActiveItem, itemIndex: number) => {
              return this.renderSectionItem(child.id, child)
            })}

          {this.maybeRenderNonData(actionGroups.after)}
        </div>
      </div>
    )
  }

  calculateContainerClass(nestingLevel: number): string {
    let containerClass = ''
    if (nestingLevel === 1) {
      containerClass += ' mt-5'
    }
    containerClass += ' sec-pad-' + nestingLevel
    return containerClass
  }

  renderSectionTitle(name: string, nestingLevel: number) {
    let headerClassSuffix = nestingLevel <= 3 ? nestingLevel : 3
    let titleClass = 'header' + headerClassSuffix
    const sectionId = this.props.resp.id
    return (
      <div>
        <span id={sectionId} className={titleClass}>
          {name}
        </span>
        {this.maybeRenderFoldingText()}
      </div>
    )
  }

  maybeRenderFoldingText() {
    if (!this.props.resp.collapsible) {
      return null
    }
    return (
      <span
        className="span-link"
        onClick={() => {
          this.setState({ collapsed: !this.state.collapsed })
        }}
      >
        [{this.state.collapsed ? 'rozwiń' : 'zwiń'}]
      </span>
    )
  }

  renderSectionItem(key: string, result: ActiveItem) {
    let actionGroups = groupButtonsByPositionDirection(
      this.props.actions.get(result.id) || []
    )

    if (result.type === 'sec') {
      return (
        <SectionComponent
          key={key}
          resp={result as ActiveSection}
          renderConfig={this.props.renderConfig}
          nestingLevel={this.props.nestingLevel + 1}
          onQuestionChanged={this.props.onQuestionChanged}
          onUnitChanged={this.props.onUnitChanged}
          actions={this.props.actions}
          onRecordAction={this.props.onRecordAction}
          onSetRef={this.props.onSetRef}
        />
      )
    }
    if (result.type === 'q') {
      return (
        <div key={key}>
          <QuestionComponent
            key={key}
            resp={result as ActiveQuestion}
            renderConfig={this.props.renderConfig}
            onQuestionChanged={this.props.onQuestionChanged}
            onUnitChanged={this.props.onUnitChanged}
          >
            {actionGroups.right_from.map(
              (actionDef: ActionDef, index: number) => {
                return renderRightFromButton(
                  actionDef,
                  index,
                  this.props.onRecordAction
                )
              }
            )}
          </QuestionComponent>
          {this.maybeRenderNonData(actionGroups.after)}
        </div>
      )
    }
    throw new Error(`unsupported render item: ${JSON.stringify(result)}`)
  }

  maybeRenderNonData(afterActionGroups: ActionDef[][]): React.ReactNode {
    if (afterActionGroups.length === 0) {
      return null
    }
    return (
      <ActionButtonsComponent
        actionGroups={afterActionGroups}
        onRecordAction={this.props.onRecordAction}
      />
    )
  }
}
